import { actionsAdminLoadSuccess, error, finish } from './actionsAdminSlice'
import { requestActionsAdminApi } from 'utils'

export const requestActionsAdmin = (id) => async (dispatch) => {
    try {
        const responce = await requestActionsAdminApi(id)
        dispatch(actionsAdminLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}
