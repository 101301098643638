import { requestAddMarkerAdminApi, requestDeleteMarkerAdminApi } from 'utils'
import { markerAdd, error, finish, loading } from './markerAdminSlice'
import { requestPLanAdmin } from '../floorPlanAdmin'
import { requestMarkersAdmin } from '../markersAdmin'

export const requestAddMarkerAdmin = (clientId, planId, data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestAddMarkerAdminApi(planId, data)
        if(responce.data.data) {
            dispatch(markerAdd(responce.data.data))
        }
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
        dispatch(requestPLanAdmin(clientId))
        dispatch(requestMarkersAdmin(planId))
    }
}

export const requestDeleteMarkerAdmin = (planId, markerId) => async (dispatch) => {
    try {
        await requestDeleteMarkerAdminApi(planId, markerId)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestMarkersAdmin(planId))
    }
}
