import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Spin, Typography } from 'antd'

import { requestRolesAdmin, requestUsersAdmin } from 'redux/configStore'
import { FilterAdmin, ListEmployee } from 'components/_molecules'
import { ReactComponent as AddIcon } from 'images/back.svg'
import { BtnBack } from 'components/_atoms'
import { LanguageContext } from 'helpers'
import { routes } from 'router'
import './style.scss'

export const AdminEmployee = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { language } = useContext(LanguageContext)

    const user = useSelector((state) => state.user.user)
    const dataUsers = useSelector((state) => state.usersAdmin.usersAdmin)
    const loadingUsers = useSelector((state) => state.usersAdmin.isLoading)

    const roles = useSelector((state) => state.rolesAdmin.rolesAdmin)
    const rolesLoading = useSelector((state) => state.rolesAdmin.isLoading)

    const [roleId, setRoleId] = useState('')
    const [inStaff, setInStaff] = useState('')
    const [dateRange, setDateRange] = useState([])
    const [disable, setDisable] = useState(true)
    const [allEmployee, setAllEmployee] = useState(false)

    const navigateAddEmployee = () => {
        navigate(routes.addEmployee)
    }

    useEffect(() => {
        if (allEmployee === true) {
            dispatch(requestUsersAdmin(inStaff, roleId))
        } else {
            dispatch(requestUsersAdmin(inStaff, roleId, dateRange[1], dateRange[0]))
        }
    }, [dispatch, inStaff, roleId, dateRange, allEmployee])

    useEffect(() => {
        dispatch(requestRolesAdmin(language))
    }, [language])

    return (
        <>
            <div className="wrapper-admin">
                <BtnBack route={routes.home} />
                <Typography className="employee-title">{language === 'ru' ? `Сотрудники`: `Employees`}</Typography>
            </div>
            <FilterAdmin page="employee"
                roles={roles}
                roleId={roleId}
                inStaff={inStaff}
                dateRange={dateRange}
                setRoleId={setRoleId}
                setInStaff={setInStaff}
                rolesLoading={rolesLoading}
                setDateRange={setDateRange}
                setDisable={setDisable}
                disable={disable}
                setAllEmployee={setAllEmployee}
            />
            {user.id === 1 && 
                <div className="caralog">
                    <div className="catalog-add" onClick={navigateAddEmployee}>
                        <AddIcon />
                        <Typography className="catalog-add__title">{language === 'ru' ? `Добавить сотрудника`: `Add an employee`}</Typography>
                    </div>
                    <Typography className="catalog-sum">{language === 'ru' ? `Суммарно`: `Total`} <span>{Array.isArray(dataUsers) && dataUsers.length}</span></Typography>
                </div>
            }
            {loadingUsers ? <Spin className="spin-list" />:
                <ListEmployee data={Array.isArray(dataUsers) ? dataUsers : []} inStaff={inStaff} roleId={roleId} date_to={dateRange[1]} date_from={dateRange[0]} />
            }
        </>
    )
}