import { List, Button, Typography, Empty } from 'antd'
import { useState, useContext } from 'react'

import { ReactComponent as SelectIcon } from 'images/selectIconOrange.svg'
import { CardClient } from 'components/_atoms'
import { LanguageContext } from 'helpers'
import './style.scss'

export const ListClient = ({ data, link, linkEdit }) => {
    const [count, setCount] = useState(9)
    const { language } = useContext(LanguageContext)

    const array = Array.isArray(data) ? data.slice(0, count) : []

    const loadMore = () => {
        setCount(count + 9)
    }

    return (
        <div className="list-client">
            <List
                grid={{
                    column: 3,
                }}
                dataSource={array}
                locale={{
                    emptyText: (
                        <Empty description={language === 'ru' ? `Нет данных для отображения`: `No data to display`} />
                      )
                }}
                renderItem={(item) => (
                    <List.Item key={item.id}>
                        <CardClient
                            link={link}
                            item={item}
                            linkEdit={linkEdit}
                        />
                    </List.Item>
                )}
            />
            {data.length > 9 && array.length < data.length &&
                <div className="list-client__load-more">
                    <Button className="list-client__load-more__btn" onClick={loadMore}>
                        <Typography className="list-client__load-more__btn__title">{language === 'ru' ? `Показать ещё`: `Show more`}</Typography>
                        <SelectIcon className="list-client__load-more__btn__icon"/>
                    </Button>
                </div>
            }
        </div>
    )
}