import React, { createContext, useState, useEffect } from 'react'

export const LanguageContext = createContext()

export const LanguageProvider = ({ children }) => {
    const storedLanguage = localStorage.getItem('lang')
    const [language, setLanguage] = useState(storedLanguage || 'ru')

    const changeLanguage = (newLanguage) => {
        setLanguage(newLanguage)
        localStorage.setItem('lang', newLanguage)
    };

    useEffect(() => {
        if (storedLanguage) {
            setLanguage(storedLanguage)
        }
    }, [])

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
        {children}
        </LanguageContext.Provider>
    )
}
