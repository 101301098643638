import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_USERS_ADMIN } from './constant'

const initialState = {
    usersAdmin: [],
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const usersSlice = createSlice({
    name: MODULE_NAME_USERS_ADMIN,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        usersAdminLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.usersAdmin = action.payload
        },
        userAdminAdd(state, action) {
            state.isLoaded = true
            state.usersAdmin = [...state.usersAdmin, action.payload]
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default usersSlice.reducer
export const { loading, error, usersAdminLoadSuccess, userAdminAdd, finish } = usersSlice.actions