import { HTTP_METHODS } from 'helpers'
import { Fetcher } from 'service'

const fetcher = new Fetcher()

export const requestUsersAdminApi = (in_staff, role_id, date_to, date_from) =>
    fetcher.requestToReceive({
        url: 'admin/users',
        method: HTTP_METHODS.GET,
        params: {in_staff, role_id, date_to, date_from}
    })

export const requestUserAdminApi = (id) =>
    fetcher.requestToReceive({
        url: `admin/users/${id}`,
        method: HTTP_METHODS.GET,
    })

export const requestAddUserAdminApi = (data, lang) =>
    fetcher.requestToReceive({
        url: 'admin/users',
        method: HTTP_METHODS.POST,
        params: {lang},
        data,
    })

export const requestEditUserAdminApi = (idUser, data) =>
    fetcher.requestToReceive({
        url: `admin/users/${idUser}`,
        method: HTTP_METHODS.PUT,
        data,
    })

export const requestDeleteUserAdminApi = (idUser) =>
    fetcher.requestToReceive({
        url: `admin/users/${idUser}`,
        method: HTTP_METHODS.DELETE,
    })

export const requestRolesAdminApi = (lang) =>
    fetcher.requestToReceive({
        url: 'admin/roles',
        method: HTTP_METHODS.GET,
        params: {lang}
    })

export const requestChangeUserNoteApi = (id) =>
    fetcher.requestToReceive({
        url: `admin/users/${id}/note`,
        method: HTTP_METHODS.GET,
    })

export const requestChangeStatusUserAdminApi = (idUser, data) =>
    fetcher.requestToReceive({
        url: `admin/users/${idUser}/status`,
        method: HTTP_METHODS.PATCH,
        data,
    })

export const requestChangeNoteUserAdminApi = (idUser, data) =>
    fetcher.requestToReceive({
        url: `admin/users/${idUser}/note`,
        method: HTTP_METHODS.PATCH,
        data,
    })

export const requestEditUserProfileAdminApi = (userId, data) =>
    fetcher.requestToReceive({
        url: `admin/users/${userId}/profile`,
        method: HTTP_METHODS.PATCH,
        data,
    })

export const requestResetPasswordAdminApi = (userId, data) =>
    fetcher.requestToReceive({
        url: `admin/users/${userId}/password`,
        method: HTTP_METHODS.PATCH,
        data,
    })