import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_ROLES_ADMIN } from './constant'

const initialState = {
    rolesAdmin: [],
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const rolesSlice = createSlice({
    name: MODULE_NAME_ROLES_ADMIN,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        rolesAdminLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.rolesAdmin = action.payload
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default rolesSlice.reducer
export const { loading, error, rolesAdminLoadSuccess, finish } = rolesSlice.actions