import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_ESTIMATE_PICKER } from './constant'

const initialState = {
    estimate: [],
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const estimateSlice = createSlice({
    name: MODULE_NAME_ESTIMATE_PICKER,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        estimateLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.estimate = action.payload
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default estimateSlice.reducer
export const { loading, error, estimateLoadSuccess, finish } = estimateSlice.actions