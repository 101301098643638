import { useNavigate } from 'react-router-dom'
import { Button, Typography } from 'antd'
import { useContext } from 'react'

import { LanguageContext } from 'helpers'
import { routes } from 'router'
import './style.scss'

export const AdminHome = () => {
    const { language } = useContext(LanguageContext)
    const navigate = useNavigate()
    
    const handleNavigate = (nav) => {
        (nav === "order") ? navigate(routes.orders) :
        (nav === "catalog") ? navigate(routes.catalog) :
        (nav === "employee") ? navigate(routes.employee) :
        navigate(routes.editProfile)
    }

    return (
        <div className="admin-menu">
            <Button className="admin-menu__btn" onClick={() => handleNavigate("order")}>
                <Typography className="admin-menu__btn__title">{language === 'ru' ? `Заказы`: `Orders`}</Typography>
                <div className="admin-menu__btn__icon-order"></div>
            </Button>
            <Button className="admin-menu__btn" onClick={() => handleNavigate("catalog")}>
                <Typography className="admin-menu__btn__title">{language === 'ru' ? `Каталог`: `Catalog`}</Typography>
                <div className="admin-menu__btn_icon-catalog"></div>
            </Button>
            <Button className="admin-menu__btn" onClick={() => handleNavigate("employee")}>
                <Typography className="admin-menu__btn__title">{language === 'ru' ? `Сотрудники`: `Employee`}</Typography>
                <div className="admin-menu__btn_icon-employee"></div>
            </Button>
            <Button className="admin-menu__btn" onClick={() => handleNavigate("edit")}>
                <Typography className="admin-menu__btn__title">{language === 'ru' ? `Редактировать профиль`: `Edit profile`}</Typography>
                <div className="admin-menu__btn_icon-edit"></div>
            </Button>
        </div>
    )
}