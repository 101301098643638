import { notification } from 'antd'

import { deviceAdminAdd, devicesAdminLoadSuccess, error, finish, loading } from './devicesSlice'
import { requestAddDeviceAdminApi, requestChangeCountDeviceAdminApi, requestChangeNoteDeviceAdminApi,
    requestDeleteDeviceAdminApi, requestDevicesAdminApi, requestEditDeviceAdminApi } from 'utils'

export const requestDevicesAdmin = (s, empty, not_empty, device_category_id, device_type_id, date_to, date_from) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestDevicesAdminApi(s, empty, not_empty, device_category_id, device_type_id, date_to, date_from)
        dispatch(devicesAdminLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requestAddDeviceAdmin = (data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestAddDeviceAdminApi(data)
        if(responce.data.data) {
            dispatch(deviceAdminAdd(responce.data.data))
        }
        dispatch(requestDevicesAdmin())
    } catch (err) {
        dispatch(error({ error: err }))
        notification.error({
            message: 'Ошибка',
            description: err.response?.data?.message,
        })
    } finally {
        dispatch(finish())
    }
}

export const requestEditDeviceAdmin = (id, data) => async (dispatch) => {
    try {
        dispatch(loading())
        await requestEditDeviceAdminApi(id, data)
    } catch (err) {
        dispatch(error({ error: err }))
        notification.error({
            message: 'Ошибка',
            description: err.response?.data?.message,
        })
    } finally {
        dispatch(finish())
    }
}

export const requestChangeNoteDeviceAdmin = (id, data) => async (dispatch) => {
    try {
        await requestChangeNoteDeviceAdminApi(id, data)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
    }
}

export const requestChangeCountDeviceAdmin = (id, data) => async (dispatch) => {
    try {
        await requestChangeCountDeviceAdminApi(id, data)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
    }
}
export const requestDeleteDeviceAdmin = (deviceId) => async (dispatch) => {
    try {
        await requestDeleteDeviceAdminApi(deviceId)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
    }
}
