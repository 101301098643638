import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_DEVICES_TYPE_ADMIN } from './constant'

const initialState = {
    devicesTypeAdmin: [],
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const devicesTypeSlice = createSlice({
    name: MODULE_NAME_DEVICES_TYPE_ADMIN,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        devicesTypeAdminLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.devicesTypeAdmin = action.payload
        },
        addDeviceTypeAdmin(state, action) {
            state.isLoaded = true
            state.devicesTypeAdmin = [...state.devicesTypeAdmin, ...action.payload]
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default devicesTypeSlice.reducer
export const { loading, error, devicesTypeAdminLoadSuccess, addDeviceTypeAdmin, finish } = devicesTypeSlice.actions