import { clientsTypeMeasurerLoadSuccess, error, finish, loading } from './clientsTypeSlice'
import { requestTypeClientsMeasurerApi } from 'utils'

export const requestTypeClientsMeasurer = (language) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestTypeClientsMeasurerApi(language)
        dispatch(clientsTypeMeasurerLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}
