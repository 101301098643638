import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_CLIENT_DEVICES_PICKER } from './constant'

const initialState = {
    clientDevices: [],
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const clientDevicesSlice = createSlice({
    name: MODULE_NAME_CLIENT_DEVICES_PICKER,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        clientDevicesLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.clientDevices = action.payload
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default clientDevicesSlice.reducer
export const { loading, error, clientDevicesLoadSuccess, finish } = clientDevicesSlice.actions