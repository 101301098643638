import { Form, DatePicker, Button, Typography, Input, Radio, Spin } from 'antd'
import { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { requestAddUserAdmin, requestRolesAdmin } from 'redux/configStore'
import { ADMIN_USER_FORM_REQUIRED_FIELDS } from 'constants/data/data'
import { ReactComponent as DateIcon } from 'images/dateIcon.svg'
import { ModalSuccess } from 'components/_molecules'
import { BtnBack } from 'components/_atoms'
import { LanguageContext } from 'helpers'
import { routes } from 'router'
import './style.scss'
import TextArea from 'antd/es/input/TextArea'

export const AdminAddEmployee = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { language } = useContext(LanguageContext)

    const roles = useSelector((state) => state.rolesAdmin.rolesAdmin)
    const rolesLoading = useSelector((state) => state.rolesAdmin.isLoading)

    const userError = useSelector((state) => state.usersAdmin.error)
    const isUserLoading = useSelector((state) => state.usersAdmin.isLoading)

    const [role, setRole] = useState('')
    const [phone, setPhone] = useState('')
    const [birthdate, setBirthdate] = useState('')
    const [dateHired, setDateHired] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)

    const onChangeBirthdate = (date, dateString) => {
        setBirthdate(dateString)
    }

    const onChangeDateHired = (date, dateString) => {
        setDateHired(dateString)
    }

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
        navigate(routes.employee)
    }

    const onChangeRole = (e) => {
        setRole(e.target.value)
    }

    const handlePhoneChange = (value, country) => {
        setPhone(value)
    }

    useEffect(() => {
        dispatch(requestRolesAdmin(language))
    }, [dispatch, language])

    const onFinish = (values) => {
        const data = {
            login: values.login,
            password: values.password,
            firstname: values.firstname,
            surname: values.surname,
            role_id: role,
            citizenship: values.citizenship,
            address: values.address,
            birthdate: birthdate,
            phone: values.phone,
            email: values.email,
            when_hired: dateHired,
            note: values.note,
            in_staff: true
        }
        dispatch(requestAddUserAdmin(data, language))
        showModal()
    }

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

    const onValuesChange = (changedValues, allValues) => {
        const isSubmitDisabled = !ADMIN_USER_FORM_REQUIRED_FIELDS.every(name => !!form.getFieldValue(name))
            || !!form.getFieldsError().filter(({ errors }) => errors.length).length
        setIsSubmitDisabled(isSubmitDisabled)
    }

    return (
        <>
            <div className="wrapper-admin">
                <BtnBack route={routes.employee} />
                <Typography className="employee-title">{language === 'ru' ? `Сотрудник`: `Employee`}</Typography>
            </div>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
                className="add-emplyee__form"
                onValuesChange={onValuesChange}
            >
                <Form.Item
                    name="role"
                    label={language === 'ru' ? `Тип сотрудника`: `Employee type`}
                >
                    <div className="add-emplyee__form__wrapper">
                        {rolesLoading ? <Spin className="spin"/> :
                            <Radio.Group className="add-emplyee__form__wrapper__radio-group" onChange={onChangeRole} value={role}>
                                {roles.map((item, index) =>
                                    <Radio value={item.id} key={`clientsType_${index}`}>{item.title}</Radio>
                                )}
                            </Radio.Group>
                        }
                    </div>
                </Form.Item>
                <Form.Item
                    name="firstname"
                    label={language === 'ru' ? `Имя *`: `Name *`}
                    rules={[{ required: true, message: `${language === 'ru' ? `Введите имя`: `Enter a name`}` }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="surname"
                    label={language === 'ru' ? `Фамилия *`: `Surname *`}
                    rules={[{ required: true, message: `${language === 'ru' ? `Введите фамилию`: `Enter your surname`}` }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="login"
                    label={language === 'ru' ? `Логин *`: `Login *`}
                    rules={[{ required: true, message: `${language === 'ru' ? `Введите логин`: `Enter your username`}` }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="password"
                    label={language === 'ru' ? `Пароль *`: `Password *`}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="birthdate"
                    label={language === 'ru' ? `Дата рождения *`: `Date of birth *`}
                    rules={[{ required: true, message: `${language === 'ru' ? `Введите дату рождения`: `Enter your date of birth`}` }]}
                >
                    <DatePicker
                        placeholder=''
                        suffixIcon={<DateIcon />}
                        onChange={onChangeBirthdate}
                        className="add-emplyee__form__date"
                    />
                </Form.Item>
                <Form.Item
                    name="citizenship"
                    label={language === 'ru' ? `Гражданство *`: `Citizenship *`}
                    rules={[{ required: true, message: `${language === 'ru' ? `Введите гражданство`: `Enter your citizenship`}` }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="address"
                    label={language === 'ru' ? `Адрес *`: `Address *`}
                    rules={[{ required: true, message: `${language === 'ru' ? `Введите адрес`: `Enter the address`}` }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="phone"
                    label={language === 'ru' ? `Номер телефона *`: `Phone number *`}
                    rules={[{ required: true, message: `${language === 'ru' ? `Введите номер телефона`: `Enter your phone number`}` }]}
                >
                    <PhoneInput
                        country={'ge'}
                        value={phone}
                        onChange={handlePhoneChange}
                        inputComponent={Input}
                        inputProps={{
                            name: 'phone',
                            required: true,
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email *"
                    rules={[{ required: true, message: `Введите email`}]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="when_hired"
                    label={language === 'ru' ? `Дата принятия на работу *`: `Date of employment *`}
                    rules={[{ required: true, message: `${language === 'ru' ? `Введите дату принятия на работу`: `Enter the date of employment`}` }]}
                >
                    <DatePicker
                        placeholder=''
                        suffixIcon={<DateIcon />}
                        onChange={onChangeDateHired}
                        className="add-emplyee__form__date"
                    />
                </Form.Item>
                <Form.Item
                    name="note"
                    label={language === 'ru' ? `Заметка`: `Note`}
                >
                    <TextArea className="add-emplyee__form__note" placeholder={language === 'ru' ? `Введите текст`: `Enter the text`}/>
                </Form.Item>
                <Form.Item shouldUpdate>
                    <Button
                        className="client-add__form__btn"
                        type="primary"
                        htmlType="submit"
                        disabled={isSubmitDisabled || isUserLoading}
                    >
                        <Typography className="client-add__form__btn_title">{language === 'ru' ? `Сохранить`: `Save`}</Typography>
                    </Button>
                </Form.Item>
            </Form>
            {!isUserLoading && !userError &&
                <ModalSuccess
                    isModalOpen={isModalOpen}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    title={language === 'ru' ? `Сотрудник сохранён`: `Employee saved`}
                    route={routes.employee}
                />
            }
        </>
    )
}
