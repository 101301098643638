import { actionsLoadSuccess, error, finish } from './actionsSlice'
import { requestActionsPickerApi } from 'utils'

export const requestActionsPicker = (id) => async (dispatch) => {
    try {
        const responce = await requestActionsPickerApi(id)
        dispatch(actionsLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}
