import { requestAddImageAdminApi, requestDeleteImageAdminApi } from 'utils'
import { imageAdminAdd, error, finish, loading} from './imageAdminSlice'
import { requestPLanAdmin } from '../floorPlanAdmin'

export const requestAddImageAdmin = (id, data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestAddImageAdminApi(id, data)
        if(responce.data.data) {
            dispatch(imageAdminAdd(responce.data.data))
        }
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
        dispatch(requestPLanAdmin(id))
    }
}

export const requestDeleteImageAdmin = (idClient, id) => async (dispatch) => {
    try {
        await requestDeleteImageAdminApi(idClient, id)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestPLanAdmin(idClient))
    }
}