import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import { MODULE_NAME_USER, MODULE_NAME_USERS_ADMIN, MODULE_NAME_PLAN_PICKER, MODULE_NAME_PLAN_MEASURER,
    MODULE_NAME_CLIENT_PICKER, MODULE_NAME_CLIENTS_ADMIN, MODULE_NAME_IMAGE_MEASURER, MODULE_NAME_VIDEO_MEASURER,
    MODULE_NAME_DEVICES_PICKER, MODULE_NAME_ACTIONS_PICKER, MODULE_NAME_CLIENTS_PICKER, MODULE_NAME_MARKER_MEASURER,
    MODULE_NAME_ESTIMATE_PICKER, MODULE_NAME_CLIENT_MEASURER, MODULE_NAME_MARKERS_MEASURER,
    MODULE_NAME_ACTIONS_MEASURER, MODULE_NAME_CLIENTS_MEASURER, MODULE_NAME_PLAN_MARKER_PICKER,
    MODULE_NAME_CLIENT_DEVICES_PICKER, MODULE_NAME_DEVICEGATEGORY_MEASURER, MODULE_NAME_DEVICES_ADMIN,
    MODULE_NAME_DEVICES_TYPE_ADMIN, MODULE_NAME_DEVICES_CATEGORY_ADMIN, MODULE_NAME_DEVICE_ADMIN,
    MODULE_NAME_ROLES_ADMIN, MODULE_NAME_CLIENTS_TYPE_MEASURER, MODULE_NAME_USER_ADMIN,
    MODULE_NAME_CLIENTS_TYPE_ADMIN, MODULE_NAME_CLIENT_ADMIN, MODULE_NAME_ACTIONS_ADMIN,
    MODULE_NAME_PLAN_ADMIN, MODULE_NAME_MARKERS_ADMIN, MODULE_NAME_MARKER_ADMIN,
    MODULE_NAME_ESTIMATE_ADMIN, MODULE_NAME_IMAGE_ADMIN, MODULE_NAME_VIDEO_Admin, MODULE_NAME_DEVICES_SELECTION_ADMIN, MODULE_NAME_PROTOCOL_ADMIN, MODULE_NAME_MANUFACTURE_ADMIN, MODULE_NAME_TRANSACTIONS_ADMIN, MODULE_NAME_TRANSACTION_ADMIN,
} from './configStore'

import deviceSelectionAdminReducer from './configStore/admin/deviceSelection/deviceSelectionSlice'
import transactionsAdminReducer from './configStore/admin/transactions/transactionsAdminSlice'
import devicesCategoryReducer from './configStore/admin/devicesCategory/devicesCategorySlice'
import deviceMeasurerReducer from './configStore/measurer/deviceGategory/deviceGategorySlice'
import clientsTypeMeasurerReducer from './configStore/measurer/clientsType/clientsTypeSlice'
import manufacturerAdminReducer from './configStore/admin/manufacturer/manufacturerSlice'
import clientDevicesReducer from './configStore/picker/clientDevices/clientDeviceSlice'
import devicesTypeAdminReducer from './configStore/admin/devicesType/devicesTypeSlice'
import clientsTypeAdminReducer from './configStore/admin/clientsType/clientsTypeSlice'
import planAdminReducer from './configStore/admin/floorPlanAdmin/floorPlanAdminSlice'
import transactionAdminReducer from './configStore/admin/transaction/transactionSlice'
import clientMeasurerReducer from './configStore/measurer/client/clientMeasurerSlice'
import markersAdminReducer from './configStore/admin/markersAdmin/markersAdminSlice'
import estimateAdminReducer from './configStore/admin/estimate/estimateAdminSlice'
import deivcesClientPickerReducer from './configStore/picker/devices/devicesSlice'
import markerAdminReducer from './configStore/admin/markerAdmin/markerAdminSlice'
import planMeasurerReducer from './configStore/measurer/floorPlan/floorPlanSlice'
import clientsMeasurerReducer from './configStore/measurer/clients/clientsSlice'
import markersMeasurerReducer from './configStore/measurer/markers/markersSlice'
import estimatePickerReducer from './configStore/picker/estimate/estimateSlice'
import actionsAdminReducer from './configStore/admin/actions/actionsAdminSlice'
import actionsMeasurerReducer from './configStore/measurer/action/actionsSlice'
import imageAdminReducer from './configStore/admin/imageAdmin/imageAdminSlice'
import videoAdminReducer from './configStore/admin/videoAdmin/videoAdminSlice'
import markerMeasurerReducer from './configStore/measurer/marker/markerSlice'
import protocolAdminReducer from './configStore/admin/protocol/protocolSlice'
import clientAdminReducer from './configStore/admin/client/clientAdminSlice'
import clientsPickerReducer from './configStore/picker/clients/clientsSlice'
import actionsPickerReducer from './configStore/picker/action/actionsSlice'
import imageMeasurerReducer from './configStore/measurer/image/imageSlice'
import videoMeasurerReducer from './configStore/measurer/video/videoSlice'
import clientsAdminReducer from './configStore/admin/clients/clientsSlice'
import devicesAdminReducer from './configStore/admin/devices/devicesSlice'
import pickerMarkerReducer from './configStore/picker/marker/markerSlice'
import clientPickerReducer from './configStore/picker/client/clientSlice'
import deviceAdminReducer from './configStore/admin/device/deviceSlice'
import rolesAdminReducer from './configStore/admin/roles/rolesSlice'
import usersAdminReducer from './configStore/admin/users/usersSlice'
import planPickerReducer from './configStore/picker/plan/planSlice'
import userAdminReducer from './configStore/admin/user/userSlice'
import userReducer from './configStore/user/userSlice'

export const store = configureStore({
    reducer: {
        [MODULE_NAME_USER]: userReducer,

        [MODULE_NAME_PLAN_PICKER]: planPickerReducer,
        [MODULE_NAME_CLIENT_PICKER]: clientPickerReducer,
        [MODULE_NAME_CLIENTS_PICKER]: clientsPickerReducer,
        [MODULE_NAME_VIDEO_MEASURER]: videoMeasurerReducer,
        [MODULE_NAME_IMAGE_MEASURER]: imageMeasurerReducer,
        [MODULE_NAME_ACTIONS_PICKER]: actionsPickerReducer,
        [MODULE_NAME_CLIENT_MEASURER]: clientMeasurerReducer,
        [MODULE_NAME_ESTIMATE_PICKER]: estimatePickerReducer,
        [MODULE_NAME_PLAN_MARKER_PICKER]: pickerMarkerReducer,
        [MODULE_NAME_DEVICES_PICKER]: deivcesClientPickerReducer,
        [MODULE_NAME_CLIENT_DEVICES_PICKER]: clientDevicesReducer,
        
        [MODULE_NAME_PLAN_MEASURER]: planMeasurerReducer,
        [MODULE_NAME_MARKER_MEASURER]: markerMeasurerReducer,
        [MODULE_NAME_MARKERS_MEASURER]: markersMeasurerReducer,
        [MODULE_NAME_CLIENTS_MEASURER]: clientsMeasurerReducer,
        [MODULE_NAME_ACTIONS_MEASURER]: actionsMeasurerReducer,
        [MODULE_NAME_DEVICEGATEGORY_MEASURER]: deviceMeasurerReducer,
        [MODULE_NAME_CLIENTS_TYPE_MEASURER]: clientsTypeMeasurerReducer, 

        [MODULE_NAME_TRANSACTION_ADMIN]: transactionAdminReducer,
        [MODULE_NAME_VIDEO_Admin]: videoAdminReducer,
        [MODULE_NAME_IMAGE_ADMIN]: imageAdminReducer,
        [MODULE_NAME_PLAN_ADMIN]: planAdminReducer,
        [MODULE_NAME_USER_ADMIN]: userAdminReducer,
        [MODULE_NAME_ROLES_ADMIN]: rolesAdminReducer,
        [MODULE_NAME_USERS_ADMIN]: usersAdminReducer,
        [MODULE_NAME_DEVICE_ADMIN]: deviceAdminReducer,
        [MODULE_NAME_MARKER_ADMIN]: markerAdminReducer,
        [MODULE_NAME_CLIENT_ADMIN]: clientAdminReducer,
        [MODULE_NAME_MARKERS_ADMIN]: markersAdminReducer,
        [MODULE_NAME_CLIENTS_ADMIN]: clientsAdminReducer,
        [MODULE_NAME_DEVICES_ADMIN]: devicesAdminReducer,
        [MODULE_NAME_ACTIONS_ADMIN]: actionsAdminReducer,
        [MODULE_NAME_ESTIMATE_ADMIN]: estimateAdminReducer,
        [MODULE_NAME_PROTOCOL_ADMIN]: protocolAdminReducer,
        [MODULE_NAME_TRANSACTIONS_ADMIN]: transactionsAdminReducer,
        [MODULE_NAME_MANUFACTURE_ADMIN]: manufacturerAdminReducer,
        [MODULE_NAME_DEVICES_TYPE_ADMIN]: devicesTypeAdminReducer,
        [MODULE_NAME_CLIENTS_TYPE_ADMIN]: clientsTypeAdminReducer,
        [MODULE_NAME_DEVICES_CATEGORY_ADMIN]: devicesCategoryReducer,
        [MODULE_NAME_DEVICES_SELECTION_ADMIN]: deviceSelectionAdminReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})
