import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_MANUFACTURE_ADMIN } from './constant'

const initialState = {
    manufacturerAdmin: [],
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const manufacturerSlice = createSlice({
    name: MODULE_NAME_MANUFACTURE_ADMIN,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        manufacturerAdminLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.manufacturerAdmin = action.payload
        },
        addmanufacturerAdmin(state, action) {
            state.isLoaded = true
            state.manufacturerAdmin = [...state.manufacturerAdmin, ...action.payload]
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default manufacturerSlice.reducer
export const { loading, error, manufacturerAdminLoadSuccess, addmanufacturerAdmin, finish } = manufacturerSlice.actions