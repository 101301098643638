import { HTTP_METHODS } from 'helpers'
import { Fetcher } from 'service'

const fetcher = new Fetcher()

export const requestClientsAdminApi = (stage, type, status, date_to, date_from, lang) =>
    fetcher.requestToReceive({
        url: 'admin/clients',
        method: HTTP_METHODS.GET,
        params: {stage, type, status, date_to, date_from, lang}
    })

export const requestAddClientAdminApi = (data) =>
    fetcher.requestToReceive({
        url: 'admin/clients',
        method: HTTP_METHODS.POST,
        data
    })

export const requestClientsTypeAdminApi = (lang) =>
    fetcher.requestToReceive({
        url: 'admin/clients/types/list',
        method: HTTP_METHODS.GET,
        params: {lang}
    })

export const requestUpdateContractPriceClientApi = (clientId, data) =>
    fetcher.requestToReceive({
        url: `admin/clients/${clientId}/contract-price`,
        method: HTTP_METHODS.PATCH,
        data
    })

export const requestClientAdminApi = (clientId, lang) =>
    fetcher.requestToReceive({
        url: `admin/clients/${clientId}`,
        method: HTTP_METHODS.GET,
        params: {lang}
    })

export const requestEditClientAdminApi = (clientId, data) =>
    fetcher.requestToReceive({
        url: `admin/clients/${clientId}`,
        method: HTTP_METHODS.PUT,
        data
    })

export const requestActionsAdminApi = (id) =>
    fetcher.requestToReceive({
        url: `admin/clients/${id}/actions`,
        method: HTTP_METHODS.GET,
    })

export const requestFloorPlanAdminApi = (clientId) =>
    fetcher.requestToReceive({
        url: `admin/clients/${clientId}/floor-plan`,
        method: HTTP_METHODS.GET
    })

export const requestMarkersAdminApi = (planId) =>
    fetcher.requestToReceive({
        url: `/admin/floor-plans/${planId}/markers`,
        method: HTTP_METHODS.GET
    })

export const requestAddMarkerAdminApi = (planId, data) =>
    fetcher.requestToReceive({
        url: `admin/floor-plans/${planId}/markers`,
        method: HTTP_METHODS.POST,
        data
    })

export const requestDeleteMarkerAdminApi = (planId, markerId) =>
    fetcher.requestToReceive({
        url: `admin/floor-plans/${planId}/markers/${markerId}`,
        method: HTTP_METHODS.DELETE
    })

export const requestUpdateFloorPlanAdminCommentApi = (categoryId, data) =>
    fetcher.requestToReceive({
        url: `admin/comments/${categoryId}`,
        method: HTTP_METHODS.PATCH,
        data
    })

export const requestEsimateAdminApi = (id, lang) =>
    fetcher.requestToReceive({
        url: `admin/clients/${id}/estimate`,
        method: HTTP_METHODS.GET,
        params: {lang}
    })

export const requestAddImageAdminApi = (id, data) =>
    fetcher.requestToReceive({
        url: `admin/clients/${id}/images`,
        method: HTTP_METHODS.POST,
        data
    })

export const requestDeleteImageAdminApi = (idClient, id) =>
    fetcher.requestToReceive({
        url: `admin/clients/${idClient}/images/${id}`,
        method: HTTP_METHODS.DELETE,
    })

export const requestAddVideoAdminApi = (id, data) =>
    fetcher.requestToReceive({
        url: `admin/clients/${id}/videos`,
        method: HTTP_METHODS.POST,
        data
    })

export const requestDeleteVideoAdminApi = (idClient, id) =>
    fetcher.requestToReceive({
        url: `admin/clients/${idClient}/videos/${id}`,
        method: HTTP_METHODS.DELETE,
    })


export const requestEditPlanAdminApi = (idClient, planId, data) =>
    fetcher.requestToReceive({
        url: `admin/clients/${idClient}/floor-plan/${planId}?_method=PUT`,
        method: HTTP_METHODS.POST,
        data
    })

export const requestAddPlanAdminApi = (id, data) =>
    fetcher.requestToReceive({
        url: `admin/clients/${id}/floor-plan`,
        method: HTTP_METHODS.POST,
        data
    })

export const requestClientReadyAdminApi = (id) =>
    fetcher.requestToReceive({
        url: `admin/clients/${id}/ready`,
        method: HTTP_METHODS.PATCH,
    })

export const requestClientNotReadyAdminApi = (id) =>
    fetcher.requestToReceive({
        url: `admin/clients/${id}/not-ready`,
        method: HTTP_METHODS.PATCH,
    })

export const requestClientCheckedAdminApi = (id) =>
    fetcher.requestToReceive({
        url: `admin/clients/${id}/checked`,
        method: HTTP_METHODS.PATCH,
    })

export const requestClientNotCheckedAdminApi = (id) =>
    fetcher.requestToReceive({
        url: `admin/clients/${id}/not-checked`,
        method: HTTP_METHODS.PATCH,
    })

export const requestDeleteDeviceCategoryAdminApi = (idDeviceCategoryMarker) =>
    fetcher.requestToReceive({
        url: `admin/device-category-markers/${idDeviceCategoryMarker}`,
        method: HTTP_METHODS.DELETE
    })