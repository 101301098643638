import { transactionAdminLoadSuccess, error, finish, loading } from './transactionSlice'
import { requestTransactionAdminApi } from 'utils'

export const requestTransactionAdmin = (id) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestTransactionAdminApi(id)
        dispatch(transactionAdminLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

