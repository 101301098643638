import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_CLIENTS_PICKER } from './constant'

const initialState = {
    clients: [],
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const clientsSlice = createSlice({
    name: MODULE_NAME_CLIENTS_PICKER,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        clientsLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.clients = action.payload
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default clientsSlice.reducer
export const { loading, error, clientsLoadSuccess, finish } = clientsSlice.actions