import { requestAddVideoMeasurer, requestDeleteVideoMeasurer } from 'utils'
import { videoAdd, error, finish, loading} from './videoSlice'
import { requestPLanMeasurer } from '../floorPlan'

export const requestVideoAddMeasurer = (id, data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestAddVideoMeasurer(id, data)
        if(responce.data.data) {
            dispatch(videoAdd(responce.data.data))
        }
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
        dispatch(requestPLanMeasurer(id))
    }
}

export const requestVideoDeleteMeasurer = (idClient, id) => async (dispatch) => {
    try {
        await requestDeleteVideoMeasurer(idClient, id)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestPLanMeasurer(idClient))
    }
}