import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_MARKER_MEASURER } from './constant'

const initialState = {
    marker: {},
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const markerSlice = createSlice({
    name: MODULE_NAME_MARKER_MEASURER,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        markersLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.marker = action.payload
        },
        markerAdd(state, action) {
            state.isLoaded = true
            state.marker = { ...state.marker, ...action.payload }
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default markerSlice.reducer
export const { loading, error, markerLoadSuccess, markerAdd, finish } = markerSlice.actions