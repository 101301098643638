import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useContext } from 'react'
import { Typography } from 'antd'

import { requestPLanAddAdmin } from 'redux/configStore/admin/floorPlanAdmin/action'
import { getImageFileDataFromFile } from './BtnGroupUploader.helpers'
import { ReactComponent as IconUpload } from 'images/uploadIcon.svg'
import { requestPLanEditAdmin } from 'redux/configStore'
import { LanguageContext } from 'helpers'
import './style.scss'

export const BtnGroupUploaderAdmin = ({ image, idPlan, disable }) => {
    const params = useParams()
    const dispatch = useDispatch()

    const id = params.id
    const { language } = useContext(LanguageContext)

    const handleSingleImageChange = async (event) => {
        const selectedFile = event.target.files[0]
        if (selectedFile) {
            const { file: imageFile, fileName: imageFileName, dataUrl: imageDataUrl } = await getImageFileDataFromFile(selectedFile)

            const data = {
                path: imageDataUrl.split(',')[1]
            }

            const formData = new FormData()
            formData.append('path', imageFile, imageFileName)
            formData.append('client_id', id)
            formData.append('data', JSON.stringify(data))
            dispatch(requestPLanAddAdmin(id, formData))
            if (image !== null && idPlan !== null) {
                dispatch(requestPLanEditAdmin(id, idPlan, formData))
            }
        }
    }

  return (
    <div className="btn-group-uploader">
        <form className="single-img" encType="multipart/form-data">
            <label
                htmlFor="single-img-button"
                className={!disable ? "single-img__btn": "single-img__btn_disable"}
            >
                <Typography className="single-img__btn__title">{language === 'ru' ? `Загрузить планировку`: `Download the layout`}</Typography>
                <IconUpload className="single-img__btn__icon" />
            </label>
            {!disable &&
                <input
                    type="file"
                    accept="image/*, application/pdf"
                    id="single-img-button"
                    className="btn-group-uploader__btn"
                    onChange={handleSingleImageChange}
                />
            }
        </form>
    </div>
  )
}