import { error, finish, markersAdminLoadSuccess } from './markersAdminSlice'
import { requestMarkersAdminApi } from 'utils'

export const requestMarkersAdmin = (planId) => async (dispatch) => {
    try {
        const responce = await requestMarkersAdminApi(planId)
        dispatch(markersAdminLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(markersAdminLoadSuccess([]))
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}
