import { HTTP_METHODS } from 'helpers'
import { Fetcher } from 'service'

const fetcher = new Fetcher()

export const requestTransactionsAdminApi = (clientId) =>
    fetcher.requestToReceive({
        url: `admin/transactions?client_id=${clientId}`,
        method: HTTP_METHODS.GET,
    })

export const requestTransactionAdminApi = (transactionId) =>
    fetcher.requestToReceive({
        url: `admin/transactions/${transactionId}`,
        method: HTTP_METHODS.GET,
    })

export const requestAddTransactionsAdminApi = (data) =>
    fetcher.requestToReceive({
        url: `admin/transactions`,
        method: HTTP_METHODS.POST,
        data
    })

export const requestEditTransactionsAdminApi = (transactionId, data) =>
    fetcher.requestToReceive({
        url: `admin/transactions/${transactionId}`,
        method: HTTP_METHODS.PUT,
        data
    })

export const requestDeleteTransactionsAdminApi = (transactionId) =>
    fetcher.requestToReceive({
        url: `admin/transactions/${transactionId}`,
        method: HTTP_METHODS.DELETE
    })