import { HTTP_METHODS } from 'helpers'
import { Fetcher } from 'service'

const fetcher = new Fetcher()

export const requestManufacturerAdminApi = (lang) =>
    fetcher.requestToReceive({
        url: 'admin/manufacturers',
        method: HTTP_METHODS.GET,
        params: {lang}
    })

export const requesAddManufacturerAdminApi = (data) =>
    fetcher.requestToReceive({
        url: 'admin/manufacturers',
        method: HTTP_METHODS.POST,
        data
    })

export const requesEditManufacturerAdminApi = (idManufacturer, data) =>
    fetcher.requestToReceive({
        url: `admin/manufacturers/${idManufacturer}`,
        method: HTTP_METHODS.PUT,
        data
    })

export const requestDeleteManufacturerAdminApi = (idManufacturer) =>
    fetcher.requestToReceive({
        url: `admin/manufacturers/${idManufacturer}`,
        method: HTTP_METHODS.DELETE,
    })
