import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_PLAN_MARKER_PICKER } from './constant'

const initialState = {
    markersPicker: [],
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const markerSlice = createSlice({
    name: MODULE_NAME_PLAN_MARKER_PICKER,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        markerLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.markersPicker = action.payload
        },
        finish(state) {
            state.isLoading = false
        },
        markerDeviceSelectionEditSuccess(state, action) {
            const { markerId, deviceCategoryId, deviceSelection } = action.payload
            state.markersPicker = state.markersPicker.map(marker => {
                if (marker.id === markerId) {
                    return {
                        ...marker,
                        device_categories: marker.device_categories.map(deviceCategory => {
                            if (deviceCategory.id === deviceCategoryId) {
                                return {
                                    ...deviceCategory,
                                    device_selection: deviceSelection
                                }
                            }
                            return deviceCategory
                        })
                    }
                }
                return marker
            })
        }
    }
})

export default markerSlice.reducer
export const { loading, error, markerLoadSuccess, finish, markerDeviceSelectionEditSuccess} = markerSlice.actions
