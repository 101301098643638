import { useEffect, useState, useContext } from 'react'
import { Typography, Input, Form, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { requestResetPasswordAdmin, requestUserInfo } from 'redux/configStore'
import { RESET_PASSWORD_FORM_REQUIRED_FIELDS } from 'constants'
import { ModalSuccess } from 'components/_molecules'
import { BtnBack } from 'components/_atoms'
import { LanguageContext } from 'helpers'
import { routes } from 'router'
import './style.scss'

export const AdminResetPassword = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { language } = useContext(LanguageContext)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const user = useSelector((state) => state.user.user)
    const userLoading = useSelector((state) => state.userAdmin.isLoading)
    const userError = useSelector((state) => state.userAdmin.error)

    const onFinish = (values) => {
        const data = {
            password: values.newPassword,
        }
        dispatch(requestResetPasswordAdmin(user.id, data))
        showModal()
    }

    useEffect(() => {
        dispatch(requestUserInfo())
    }, [dispatch])

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        form.resetFields()
        setIsModalOpen(false)
        navigate(routes.home)
    }

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

    const onValuesChange = (changedValues, allValues) => {
        const { newPassword, confirmPassword } = allValues
        const isSubmitDisabled =
            !RESET_PASSWORD_FORM_REQUIRED_FIELDS.every(name => !!form.getFieldValue(name)) ||
            newPassword !== confirmPassword
        setIsSubmitDisabled(isSubmitDisabled)
      }

    return (
        <>
            <div className="wrapper-admin">
                <BtnBack route={routes.editProfile} />
                <Typography className="reset__title">{language === 'ru'? `Изменение пароля`: `Changing the password`}</Typography>
            </div>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
                className="add-emplyee__form"
                onValuesChange={onValuesChange}
            >
                <Form.Item
                    name="newPassword"
                    label={language === 'ru'? `Новый пароль`: `New password`}
                    rules={[{ required: true, message: `${language === 'ru'? `Пожалуйста, введите новый пароль`: `Please enter a new password`}` }]}
                >
                    <Input.Password className="input-password"/>
                </Form.Item>

                <Form.Item
                    name="confirmPassword"
                    label={language === 'ru'? `Подтвердите пароль`: `Confirm your password`}
                    dependencies={['newPassword']}
                    rules={[
                    { required: true, message: `${language === 'ru'? `Пожалуйста, подтвердите свой новый пароль`: `Please confirm your new password`}` },
                    ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(
                                    new Error(`${language === 'ru'? `Два введенных вами пароля не совпадают`: `The two passwords you entered do not match`}`)
                                )
                            },
                        }),
                    ]}
                >
                    <Input.Password className="input-password"/>
                </Form.Item>
                <Form.Item shouldUpdate>
                    <Button
                        className="client-add__form__btn"
                        type="primary"
                        htmlType="submit"
                        disabled={isSubmitDisabled || userLoading}
                    >
                        <Typography className="client-add__form__btn_title">{language === 'ru'? `Изменить`: `Change`}</Typography>
                    </Button>
                </Form.Item>
            </Form>
            {!userLoading && !userError &&
                <ModalSuccess
                    isModalOpen={isModalOpen}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    title={language === 'ru'? `Пароль успешно изменен`: `Password changed successfully`}
                    route={routes.home}
                />
            }
        </>
    )
}
