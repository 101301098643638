import { useContext } from 'react'
import { Typography } from 'antd'

import { LanguageContext } from 'helpers'
import './style.scss'

export const EstimateTable = ({ array: deviceCategoryEstimations, className }) => {

    const { language } = useContext(LanguageContext)
    const sum = deviceCategoryEstimations.reduce((acc, obj) => {
        return acc + obj.devices?.reduce((acc, device) => {
            return acc + device.count
        }, 0)
    }, 0)

    return (
        <div className={className}>
            <div className="estimate">
                <Typography className="estimate__title">{language === 'ru' ? `Смета`: `Estimate`}</Typography>
            </div>
            <div className="estimate-table">
                {deviceCategoryEstimations.map((deviceCategoryEstimation) => {
                    const { devices: types } = deviceCategoryEstimation
                    const { title: deviceCategoryTitle } = deviceCategoryEstimation
                    return types.map(type => ({ ...type, deviceCategoryTitle }))
                }).flat().map(deviceCategoryEstimation =>
                    <div className="estimate-table__row" key={deviceCategoryEstimation.id}>
                        <div className="estimate-table__row__content">
                            <Typography className="estimate-table__title">{deviceCategoryEstimation.deviceCategoryTitle}</Typography>
                            <Typography className="estimate-table__model">{deviceCategoryEstimation.title}</Typography>
                        </div>
                        <div className="estimate-table__row__content">
                            <Typography className="estimate-table__count-title">{language === 'ru' ? `Количество`: `Quantity`}</Typography>
                            <Typography className="estimate-table__count">{deviceCategoryEstimation.count}</Typography>
                        </div>
                    </div>
                )}
                <div className="estimate-table__row-sum">
                    <Typography className="title-two">{language === 'ru' ? `Общее количество оборудования`: `Total number of equipment`}</Typography>
                    <Typography className="estimate-table__sum title-two">{sum}</Typography>
                </div>
            </div>
        </div>
    )
}