import { addDeviceCategoryAdmin, devicesCategoryAdminLoadSuccess, error, finish, loading } from './devicesCategorySlice'
import { requestAddDevicesCategoryAdminApi, requestDeleteDeviCategoryAdminApi, requestDevicesCategoryAdminApi, requestEditDevicesCategoryAdminApi } from 'utils'

export const requestDevicesCategoryAdmin = (lang) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestDevicesCategoryAdminApi(lang)
        dispatch(devicesCategoryAdminLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requestAddDevicesCategoryAdmin = (data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestAddDevicesCategoryAdminApi(data)
        if(responce.data.data) {
            dispatch(addDeviceCategoryAdmin(responce.data.data))
        }
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
        dispatch(requestDevicesCategoryAdmin())
    }
}

export const requestEditDevicesCategoryAdmin = (id, data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestEditDevicesCategoryAdminApi(id, data)
        if (responce.data.data) {
            dispatch(requestDevicesCategoryAdmin())
        }
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
    }
}

export const requestDeleteDeviCategoryAdmin = (idCategory) => async (dispatch) => {
    try {
        await requestDeleteDeviCategoryAdminApi(idCategory)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestDevicesCategoryAdmin())
    }
}