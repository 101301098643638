import { useEffect, useState, useContext, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Spin, Input, Form } from 'antd'

import { requestManufacturerAdmin, requesAddManufacturerAdmin, requesEditManufacturerAdmin, requestDeleteManufacturerAdmin } from 'redux/configStore'
import { ReactComponent as IconDelete } from 'images/deleteIcon.svg'
import { ReactComponent as IconEdit } from 'images/editIcon.svg'
import { ReactComponent as IconAdd } from 'images/addIcon.svg'
import { ButtonPrimary, Modal } from 'components/_atoms'
import { LanguageContext } from 'helpers'
import './style.scss'
import { ADMIN_SETTINGS_FORM_REQUIRED_FIELDS } from 'constants/data/data'

export const SettingsMenuManufacturer = () => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const { language } = useContext(LanguageContext)

    const manufacturers = useSelector((state) => state.manufacturerAdmin.manufacturerAdmin)
    const manufacturersLoading = useSelector((state) => state.manufacturerAdmin.isLoading)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [btnTitle, setBtnTitle] = useState('')
    const [formLabel, setFormLabel] = useState('')
    const [categoryId, setCategoryId] = useState(null)
    const [title, setTitle] = useState('')
    
    const handleAddManufacturer = () => {
        setBtnTitle(`${language === 'ru' ? `Добавить производителя`: `Add a manufacturer`}`)
        setFormLabel(`${language === 'ru' ? `Введите производителя`: `Add a manufacturer`}`)
        setCategoryId(null)
        setTitle('')
        showModal()
    }

    const handleChangeManufacturer = (idManufacturer, manufacturerTitle) => {
        setBtnTitle(`${language === 'ru' ? `Изменить производителя`: `Change the manufacturer`}`)
        setFormLabel(`${language === 'ru' ? `Изменить производителя`: `Change the manufacturer`}`)
        setCategoryId(idManufacturer)
        setTitle(manufacturerTitle)
        showModal()
    }

    const handleDeleteManufacturer = (idManufacturer) => {
        dispatch(requestDeleteManufacturerAdmin(idManufacturer))
    }

    const onFinish = (values) => {
        if(formLabel === 'Введите производителя' || formLabel === 'Add a manufacturer') {
            const data = {
                title: values.title
            }
            dispatch(requesAddManufacturerAdmin(data))
        }
        if(btnTitle === 'Изменить производителя' || btnTitle === 'Change the manufacturer') {
            const data = {
                title: values.title
            }
            dispatch(requesEditManufacturerAdmin(categoryId, data))
        }
        form.resetFields()
        handleCancel()
    } 

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    const onValuesChange = (changedValues, allValues) => {
        const isSubmitDisabled = !ADMIN_SETTINGS_FORM_REQUIRED_FIELDS.every(name => !!form.getFieldValue(name))
            || !!form.getFieldsError().filter(({ errors }) => errors.length).length
        setIsSubmitDisabled(isSubmitDisabled)
    }


    useEffect(() => {
        dispatch(requestManufacturerAdmin(language))
    }, [language])

    useLayoutEffect(() => {
        if (categoryId !== null) {
            form.setFieldsValue({
                title: title
             })
        } else {
            form.setFieldsValue({
                title: null
             })
        }
    }, [form, categoryId, title])

    return (
        <div className="settings-menu-protocol">
            <div className="settings-menu__header">
                <Typography className="settings-menu__header__title">{language === 'ru' ? `Настройки производителей`: `Manufacturer settings`}</Typography>
                <IconAdd className="settings-menu__header__icon" onClick={handleAddManufacturer}/>
            </div>
            {manufacturersLoading ? <Spin className="spin-list"/> :
                <>
                    {manufacturers.map((manufacturer) => 
                            <Typography className="settings-menu-protocol__item-title">
                                <IconEdit
                                    className="settings-menu__icon-edit"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleChangeManufacturer(manufacturer.id, manufacturer.title)
                                    }}
                                />
                                <IconDelete className="settings-menu__icon-delete"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleDeleteManufacturer(manufacturer.id)
                                    }}
                                />
                                {manufacturer.title}
                            </Typography>
                    )}
                </>
            }
            <Modal
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                component={
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off" className="settings-menu__modal"
                        onValuesChange={onValuesChange}
                    >
                        <Form.Item
                            name="title"
                            label={formLabel}
                            key={`title_${categoryId}`}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <ButtonPrimary text={btnTitle} />
                        </Form.Item>
                    </Form>
                }
            />
        </div>
    )
}