import { HTTP_METHODS } from 'helpers'
import { Fetcher } from 'service'

const fetcher = new Fetcher()

export const requestMarkersMeasurerApi = (planId) =>
    fetcher.requestToReceive({
        url: `/measurer/floor-plans/${planId}/markers`,
        method: HTTP_METHODS.GET
    })

export const requestMarkerMeasurerApi = (planId, markerId) =>
    fetcher.requestToReceive({
        url: `measurer/floor-plans/${planId}/markers/${markerId}`,
        method: HTTP_METHODS.GET
    })

export const requestAddMarkerMeasurerApi = (planId, data) =>
    fetcher.requestToReceive({
        url: `measurer/floor-plans/${planId}/markers`,
        method: HTTP_METHODS.POST,
        data
    })

export const requestEditMarkerMeasurerApi = (planId, markerId, data) =>
    fetcher.requestToReceive({
        url: `measurer/floor-plans/${planId}/markers/${markerId}`,
        method: HTTP_METHODS.PUT,
        data
    })

export const requestDeleteMarkerMeasurerApi = (planId, markerId) =>
    fetcher.requestToReceive({
        url: `measurer/floor-plans/${planId}/markers/${markerId}`,
        method: HTTP_METHODS.DELETE
    })
