export const routes = { default: '/', home: '/home', login: '/login',
    orders: '/orders',
    catalog: '/catalog',
    employee: '/employee',
    clientAdd: '/client-add',
    editClient: '/client-edit/:id',
    editProfile: '/edit-profile',
    pickerSelect: '/picker-select/:id',
    measurerLayout: '/measurer-layout/:id',
    catalogSettings: '/catalog-settings',
    resetPassword: '/reset-password',
    addDevice: '/add-device',
    addEmployee: '/add-employee',
    adminLayout: '/admin-layout/:id',
    adminEditDevice: '/admin-edit-device/:id',
    adminEditEmployee: '/admin-edit-employee/:id',
    adminClientEdit: '/admin-edit-client/:id',
    adminAddClient: '/admin-add-client'
}