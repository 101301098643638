import { List, Input, Typography, Select, Empty } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useContext, useState } from 'react'

import { requestChangeNoteUserAdmin, requestChangeStatusUserAdmin } from 'redux/configStore'
import { ReactComponent as EditIcon } from 'images/edit.svg'
import { LanguageContext } from 'helpers'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'

export const ListEmployee = ({ data, inStaff, roleId, date_to, date_from }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [state, setState] = useState(false)
    const [selectedItemId, setSelectedItemId] = useState(null)
    const user = useSelector((state) => state.user.user)
    const { language } = useContext(LanguageContext)

    const handleChange = (value, id) => {
        const data = {
            in_staff: value,
        }
        dispatch(requestChangeStatusUserAdmin(id, data, inStaff, roleId, date_to, date_from))
        setState(value.value ? false : true)
    }

    const onChange = (e, id) => {
        const data = {
            note: e.target.value,
        }
        dispatch(requestChangeNoteUserAdmin(id, data))
    }

    const navigateEditEmployee = (id) => {
        navigate(`/admin-edit-employee/${id}`)
    }

    return (
        <div className="list-employee">
            <div className="list-employee__header">
                <Typography className="list-employee__header__text">{language === 'ru' ? `Имя`: `Name`}</Typography>
                <Typography className="list-employee__header__text">{language === 'ru' ? `Статус сотрудника`: `Employee status`}</Typography>
                <Typography className="list-employee__header__text">{language === 'ru' ? `Всего заказов`: `Total orders`}</Typography>
                <Typography className="list-employee__header__text">{language === 'ru' ? `Выполненные заказы`: `Completed orders`}</Typography>
                <Typography className="list-employee__header__text">{language === 'ru' ? `Заказы в ожидании`: `Orders pending`}</Typography>
                <Typography className="list-employee__header__text">{language === 'ru' ? `Заметка`: `Note`}</Typography>
            </div>
            <List
                className="list-employee__list"
                dataSource={data}
                locale={{
                    emptyText: (
                        <Empty description={language === 'ru' ? `Нет данных для отображения`: `No data to display`} />
                      )
                }}
                renderItem={(item, index) => 
                    <List.Item key={index}>
                        {user.id === 1 ? 
                            <EditIcon className="list-employee__list__edit-icon cursor" onClick={() => navigateEditEmployee(item.id)}/>:
                            <div className="list-employee__list__edit-icon"></div>
                        }
                        {!item.in_staff ? <Typography className="list-employee__list__name__no-staff">{`${item.firstname} ${item.surname}`}</Typography>:
                            <Typography className="list-employee__list__name">{`${item.firstname} ${item.surname}`}</Typography>
                        }
                        <Select
                            defaultValue={{
                                value: item.in_staff ? true : false,
                                label: item.in_staff ? `${language === 'ru' ? `В штате`: `In the state`}` : `${language === 'ru' ? `Уволен`: `Dismissed`}`,
                            }}
                            className="list-employee__list__select"
                            onChange={(selectedOption) =>
                                handleChange(selectedOption, item.id)
                            }
                            onSelect={() => setSelectedItemId(item.id)}
                            options={[
                                { value: true, label: `${language === 'ru' ? `В штате`: `In the state`}` },
                                { value: false, label: `${language === 'ru' ? `Уволен`: `Dismissed`}`},
                            ]}
                        />
                        {!item.in_staff ?
                            <>
                                {item.statistic !== null ?
                                    <>
                                        <Typography className="list-employee__list__orders__no-staff">{item.statistic?.total}</Typography>
                                        <Typography className="list-employee__list__orders__no-staff">{item.statistic?.completed}</Typography>
                                        <Typography className="list-employee__list__orders__no-staff">{item.statistic?.waiting}</Typography>
                                    </>:
                                    <>
                                        <Typography className="list-employee__list__orders__no-staff manager">-</Typography>
                                        <Typography className="list-employee__list__orders__no-staff manager">-</Typography>
                                        <Typography className="list-employee__list__orders__no-staff manager">-</Typography>
                                    </>
                                }
                            </>:
                            <>
                                {item.statistic !== null ?
                                    <>
                                        <Typography className="list-employee__list__orders">{item.statistic?.total}</Typography>
                                        <Typography className="list-employee__list__orders">{item.statistic?.completed}</Typography>
                                        <Typography className="list-employee__list__orders">{item.statistic?.waiting}</Typography>
                                    </>:
                                    <>
                                        <Typography className="list-employee__list__orders manager">-</Typography>
                                        <Typography className="list-employee__list__orders manager">-</Typography>
                                        <Typography className="list-employee__list__orders manager">-</Typography>
                                    </>
                                }
                            </>
                        }
                        <Input
                            className="list-device__list__input"
                            placeholder={language === 'ru' ? `Введите текст`: `Enter the text`}
                            defaultValue={item.note}
                            onChange={(event) => onChange(event, item.id)}
                        />
                    </List.Item>
                }
            />
        </div>
    )
}