import { Typography, List, Button, Empty, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'

import { ModalSuccess, ModalConfirm, EstimateTable, Statistics, PickerPlan } from 'components/_molecules'
import { requestPlanPicker, requestClientPicker, requestActionsPicker, requestEstimatePicker,
    requestPlanMarkerPicker, requestClientCheckedPicker } from 'redux/configStore'
import { ReactComponent as PrintIcon } from 'images/printIcon.svg'
import { BtnBack, CardEquipment, Table } from 'components/_atoms'
import { Gallery } from 'components/_organisms'
import { LanguageContext } from 'helpers'
import { routes } from 'router'
import './style.scss'
import { requestClientNotCheckedPicker } from 'redux/configStore/picker/client/action'

export const PickerSelect = () => {
    const params = useParams()
    const dispatch = useDispatch()

    const { language } = useContext(LanguageContext)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false)

    const id = params.id

    const dataClient = useSelector((state) => state.client.client)
    const loadingClient = useSelector((state) => state.client.isLoading)

    const plan = useSelector((state) => state.plan.plan)
    const loadingPlan = useSelector((state) => state.plan.isLoading)

    const planMarkers = useSelector((state) => state.markersPicker.markersPicker)
    const planMarkersLoading = useSelector((state) => state.markersPicker.isLoading)

    const dataEstimate = useSelector((state) => state.estimate.estimate)
    const dataStatistics = useSelector((state) => state.actions.actions)
    const disable = dataClient.date_checked !== null ? true : false
    const [disableButton, setDisableButton] = useState(false)
    const [newMarkerData, setNewMarkerData] = useState(null)

    const handlePrint = () => {
        window.print()
    }

    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const handleOkSuccess = () => {
        setIsModalOpenSuccess(false)
    }
    const handleCancelSuccess = () => {
        setIsModalOpenSuccess(false)
    }

    const confirmSelect = () => {
        setIsModalOpenSuccess(true)
    }

    const makeСhangesPicker = () => {
        dispatch(requestClientNotCheckedPicker(id))
    }

    const handleSuccess = () => {
        setIsModalOpenSuccess(false)
        setIsModalOpen(true)
        dispatch(requestClientCheckedPicker(id))
    }

    const hasNullSelection = () => {
        return planMarkers.some(device => device.device_categories
            .some(category => category.device_selection === null))
    }

    useEffect(() => {
        if(id !== null) {
            dispatch(requestPlanPicker(id))
            dispatch(requestClientPicker(id))
            dispatch(requestEstimatePicker(id))
            dispatch(requestActionsPicker(id))
        }
    }, [dispatch, id])

    useEffect(() => {
        if (plan.id !== null) {
            dispatch(requestPlanMarkerPicker(plan.id))
        }
    }, [dispatch, plan.id])

    useEffect(() => {
        setDisableButton(hasNullSelection())
    }, [planMarkers])
    
    return (
        <>
            <div className="wrapper-admin no-printme">
                <BtnBack route={routes.home} />
            </div>
            <div className="wrapper__content no-printme">
                <Typography className="wrapper__content_title">{language === 'ru' ? `Клиент`: `Client`}</Typography>
                {loadingClient ? <Spin className="spin"/>:
                    <Typography className="piker-title">
                        <span>{language === 'ru' ? `Замерщик:`: `Measurer:`}</span>
                        {` ${dataClient.user?.firstname} ${dataClient.user?.surname}`}
                    </Typography>
                }
            </div>
            {dataClient.type === "App\\Models\\Contact" ?
                <Table
                    loading={loadingClient}
                    initials={dataClient.name}
                    address={dataClient.address}
                    square={dataClient.info?.area}
                    phone={dataClient.info?.phone}
                    email={dataClient.info?.email}
                    clientType={language === 'ru' ? `Физическое лицо`: `Physical person`}
                /> :
                <Table
                    loading={loadingClient}
                    initials={dataClient.name}
                    address={dataClient.address}
                    square={dataClient.info?.contact.area}
                    phone={dataClient.info?.contact.phone}
                    email={dataClient.info?.contact.email}
                    clientType={language === 'ru' ? `Юридическое лицо`: `Legal person`}
                />
            }
            <div className="wrapper__content no-printme">
                <Typography className="wrapper__content_title">{language === 'ru'? `Планировка`: `Layout`}</Typography>
            </div>
            <div className="no-printme">
                {loadingPlan && dataClient ? <Spin className="spin-list" /> :
                    <PickerPlan
                        dataPlan={plan}
                        markers={planMarkers}
                    />
                }
            </div>
            {planMarkersLoading ? <Spin className="spin-list"/> :
                <>
                    <div className="wrapper__content no-printme">
                        <Typography className="wrapper__content_title">{language === 'ru'? `Выбор оборудования`: `Equipment selection`}</Typography>
                    </div>
                    {!planMarkersLoading &&
                        <>
                            <div className="no-printme">
                                    <List
                                        className="plan-list"
                                        grid={{
                                            column: 2,
                                        }}
                                        locale={{
                                            emptyText: (
                                                <Empty description={language === 'ru'? `Нет данных для отображения`: `No data to display`} />
                                            )
                                        }}
                                        dataSource={planMarkers}
                                        renderItem={(item, index) => (
                                            <List.Item key={item.id}>
                                                <CardEquipment
                                                    count={index}
                                                    marker={item}
                                                    markerId={item.id}
                                                    planId={plan.id}
                                                    disable={disable}
                                                />
                                            </List.Item>
                                        )}
                                    />
                            </div>
                            <div className="btn-send-measurer no-printme">
                                {!disable ?
                                    <>
                                        <Button className="btn-group__send_btn" onClick={confirmSelect} disabled={disable ? disable: disableButton}>
                                            <Typography className={!disable && !disableButton ? "btn-group__send_btn_title" : "btn-group__send_btn_title-disable"}>
                                                {language === 'ru'? `Отправить на оценку`: `Submit for evaluation`}
                                            </Typography>
                                        </Button>
                                        <Typography className="btn-group__send_desc">
                                            <div className="btn-group__send_desc_icon"></div>
                                            {language === 'ru'? `После отправки редактирование будет невозможно`: `After sending, editing will not be possible`}
                                        </Typography>
                                    </>:
                                    <Button className="btn-group__send_btn" onClick={makeСhangesPicker}>
                                        <Typography className="btn-group__send_btn_title">
                                            {language === 'ru'? `Изменить подбор`: `Make adjustments`}
                                        </Typography>
                                    </Button>
                                }
                            </div>
                            <Gallery videoArray={plan.vdeos} imgArray={plan.images} />
                            {!!dataEstimate.length &&
                                <>
                                    <EstimateTable array={dataEstimate} />
                                    <div className="print no-printme">
                                        <Button className="print__btn" onClick={handlePrint}>
                                            <Typography className={language === 'ru' ? "print__btn__title": "print__btn__title-en"}>{language === 'ru'? `Распечатать`: `Print`}</Typography>
                                            <PrintIcon className="print__btn__icon"/>
                                        </Button>
                                    </div>
                                </>
                            }
                            <Statistics data={dataStatistics}/>
                        </>
                    }
                </>
            }
            <ModalConfirm
                handleOk={handleOkSuccess}
                handleSuccess={handleSuccess}
                isModalOpen={isModalOpenSuccess}
                handleCancel={handleCancelSuccess}
            />
            <ModalSuccess
                handleOk={handleOk}
                isModalOpen={isModalOpen}
                handleCancel={handleCancel}
                title={language === 'ru'? `Подбор отправлен успешно`: `The selection was sent successfully`}
                role="measurer"
            />
        </>
    )
}
