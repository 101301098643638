import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_CLIENTS_TYPE_MEASURER } from './constant'

const initialState = {
    clientsTypeMeasurer: [],
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const clientsTypeSlice = createSlice({
    name: MODULE_NAME_CLIENTS_TYPE_MEASURER,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        clientsTypeMeasurerLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.clientsTypeMeasurer = action.payload
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default clientsTypeSlice.reducer
export const { loading, error, clientsTypeMeasurerLoadSuccess, finish } = clientsTypeSlice.actions