import { useNavigate } from 'react-router-dom'
import { Typography, Button } from 'antd'
import { useContext } from 'react'

import { LanguageContext } from 'helpers'
import './style.scss'

export const BtnBack = ({ route }) => {
    const navigate = useNavigate()
    const { language } = useContext(LanguageContext)

    const handleBack = () => {
        navigate(route)
    }

    return (
        <Button className="btn-back" onClick={handleBack}>
            <div className="btn-back__icon"></div>
            <Typography className="btn-back__title">{language === 'ru'? `Назад`: `Back`}</Typography>
        </Button>
    )
}