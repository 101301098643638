import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_VIDEO_MEASURER } from './constant'

const initialState = {
    video: {},
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const videoSlice = createSlice({
    name: MODULE_NAME_VIDEO_MEASURER,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        videoLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.video = action.payload
        },
        videoAdd(state, action) {
            state.isLoaded = true
            state.video = { ...state.video, ...action.payload }
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default videoSlice.reducer
export const { loading, error, videoLoadSuccess, finish, videoAdd } = videoSlice.actions