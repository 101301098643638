import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Spin, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'

import { requestDevicesAdmin, requestDevicesCategoryAdmin, requestDevicesTypeAdmin } from 'redux/configStore'
import { ReactComponent as SettingsIcon } from 'images/settingsIcon.svg'
import { FilterAdmin, ListDevice } from 'components/_molecules'
import { ReactComponent as AddIcon } from 'images/back.svg'
import { BtnBack, ExportExcelBtn } from 'components/_atoms'
import { LanguageContext } from 'helpers'
import { routes } from 'router'
import './style.scss'

export const AdminCatalog = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { language } = useContext(LanguageContext)

    const devices = useSelector((state) => state.devicesAdmin.devicesAdmin)
    const loadingDevices = useSelector((state) => state.devicesAdmin.isLoading)

    const deviceCategory = useSelector((state) => state.devicesCategoryAdmin.devicesCategoryAdmin)
    const deviceCategoryLoading = useSelector((state) => state.devicesCategoryAdmin.isLoading)

    const deviceType = useSelector((state) => state.devicesTypeAdmin.devicesTypeAdmin)
    const deviceTypeLoading = useSelector((state) => state.devicesTypeAdmin.isLoading)
    
    const [empty, setEmpty] = useState('')
    const [search, setSearch] = useState('')
    const [notEmpty, setNotEmpty] = useState('')
    const [disable, setDisable] = useState(true)
    const [dateRange, setDateRange] = useState([])
    const [allCatalog, setAllCatalog] = useState(false)
    const [deviceTypeId, setDeviceTypeId] = useState('')
    const [deviceCategoryId, setDeviceCategoryId] = useState('')
    const [nameExcelFile, setNameExcelFile] = useState('Все устройства')
    
    const soldCountSum = Array.isArray(devices) ?
        devices?.reduce((sum, item) => sum + item?.sold_count, 0) : 0

    const navigateSettings = () => {
        navigate(routes.catalogSettings)
    }

    const navigateAddDevice = () => {
        navigate(routes.addDevice)
    }

    const handleAllCatalog = () => {
        setAllCatalog(true)
    }

    useEffect(() => {
        if(allCatalog === true) {
            dispatch(requestDevicesAdmin(search, empty, notEmpty, deviceCategoryId, deviceTypeId))
        }
        else {
            dispatch(requestDevicesAdmin(search, empty, notEmpty, deviceCategoryId, deviceTypeId, dateRange[1], dateRange[0]))
        }
    }, [dispatch, search, empty, notEmpty, deviceCategoryId, deviceTypeId, dateRange, allCatalog])

    useEffect(() => {
        dispatch(requestDevicesCategoryAdmin())
    }, [])

    useEffect(() => {
        dispatch(requestDevicesTypeAdmin(deviceCategoryId))
    }, [deviceCategoryId])

    return (
        <>
            <div className="wrapper-admin">
                <div className="catalog__wrapper">
                    <BtnBack route={routes.home}/>
                    <Typography className="catalog__title">{language === 'ru' ? `Каталог`: `Catalog`}</Typography>
                    <div className="catalog__settings" onClick={navigateSettings}>
                        <SettingsIcon className="catalog__settings__icon"/>
                        <Typography className="catalog__settings__title">{language === 'ru' ? `Настройки каталога`: `Catalog Settings`}</Typography>
                    </div>
                </div>
            </div>
            <FilterAdmin
                page="catalog"
                dateRange={dateRange}
                disable={disable}
                setEmpty={setEmpty}
                setSearch={setSearch}
                deviceType={deviceType}
                setDisable={setDisable}
                setNotEmpty={setNotEmpty}
                setDateRange={setDateRange}
                deviceTypeId={deviceTypeId}
                deviceCategory={deviceCategory}
                setDeviceTypeId={setDeviceTypeId}
                setAllCatalog={setAllCatalog}
                deviceCategoryId={deviceCategoryId}
                deviceTypeLoading={deviceTypeLoading}
                setDeviceCategoryId={setDeviceCategoryId}
                deviceCategoryLoading={deviceCategoryLoading}
                setNameExcelFile={setNameExcelFile}
            />
            <div className="caralog">
                <div className="catalog-add">
                    <div className="catalog-add" onClick={navigateAddDevice}>
                        <AddIcon />
                        <Typography className="catalog-add__title">{language === 'ru' ? `Добавить устройство`: `Add a device`}</Typography>
                    </div>
                    <Button className="btn-all" onClick={handleAllCatalog}>
                        <Typography className="btn-all__title">
                            {language === 'ru' ? `Показать все`: `Show all`}
                        </Typography>
                    </Button>
                    <ExportExcelBtn devices={devices} nameFile={nameExcelFile} />
                </div>
                <Typography className="catalog-sum">{language === 'ru' ? `Суммарно`: `Total`} <span>{soldCountSum}</span></Typography>
            </div>
            {loadingDevices ? <Spin className="spin-list" />:
                <ListDevice data={Array.isArray(devices) ? devices : []}/>
            }
        </>
    )
}