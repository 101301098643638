import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_CLIENT_ADMIN } from './constant'

const initialState = {
    clientAdmin: {},
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const clientAdminSlice = createSlice({
    name: MODULE_NAME_CLIENT_ADMIN,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        clientAdminLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.clientAdmin = action.payload
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default clientAdminSlice.reducer
export const { loading, error, clientAdminLoadSuccess, finish } = clientAdminSlice.actions
