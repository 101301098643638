import { HTTP_METHODS } from 'helpers'
import { Fetcher } from 'service'

const fetcher = new Fetcher()

export const requestProtocolAdminApi = (lang) =>
    fetcher.requestToReceive({
        url: 'admin/protocols',
        method: HTTP_METHODS.GET,
        params: {lang}
    })

export const requesAddProtocolAdminApi = (data) =>
    fetcher.requestToReceive({
        url: 'admin/protocols',
        method: HTTP_METHODS.POST,
        data
    })

export const requesEditProtocolAdminApi = (idProtocol, data) =>
    fetcher.requestToReceive({
        url: `admin/protocols/${idProtocol}`,
        method: HTTP_METHODS.PUT,
        data
    })

export const requestDeleteProtocolAdminApi = (idProtocol) =>
    fetcher.requestToReceive({
        url: `admin/protocols/${idProtocol}`,
        method: HTTP_METHODS.DELETE,
    })
