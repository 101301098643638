import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_MARKER_ADMIN } from './constant'

const initialState = {
    markerAdmin: {},
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const markerAdminSlice = createSlice({
    name: MODULE_NAME_MARKER_ADMIN,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        markerAdminLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.markmarkerAdminer = action.payload
        },
        markerAdd(state, action) {
            state.isLoaded = true
            state.markerAdmin = { ...state.markerAdmin, ...action.payload }
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default markerAdminSlice.reducer
export const { loading, error, markerAdminLoadSuccess, markerAdd, finish } = markerAdminSlice.actions