import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_CLIENT_PICKER } from './constant'

const initialState = {
    client: {},
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const clientSlice = createSlice({
    name: MODULE_NAME_CLIENT_PICKER,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        clientLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.client = action.payload
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default clientSlice.reducer
export const { loading, error, clientLoadSuccess, finish } = clientSlice.actions