import { HTTP_METHODS } from 'helpers'
import { Fetcher } from 'service'

const fetcher = new Fetcher()

export const requestDevicesAdminApi = (s, empty, not_empty, device_category_id, device_type_id, date_to, date_from) =>
    fetcher.requestToReceive({
        url: 'admin/devices',
        method: HTTP_METHODS.GET,
        params: {s, empty, not_empty, device_category_id, device_type_id, date_to, date_from}
    })

export const requestDeviceAdminApi = (idDevice) =>
    fetcher.requestToReceive({
        url: `admin/devices/${idDevice}`,
        method: HTTP_METHODS.GET,
    })

export const requestAddDeviceAdminApi = (data) =>
    fetcher.requestToReceive({
        url: 'admin/devices',
        method: HTTP_METHODS.POST,
        data
    })

export const requestEditDeviceAdminApi = (idDevice, data) =>
    fetcher.requestToReceive({
        url: `admin/devices/${idDevice}`,
        method: HTTP_METHODS.PUT,
        data
    })

export const requestDeleteDeviceAdminApi = (idDevice) =>
    fetcher.requestToReceive({
        url: `admin/devices/${idDevice}`,
        method: HTTP_METHODS.DELETE
    })
export const requestDevicesTypeAdminApi = (device_category_id, lang) =>
    fetcher.requestToReceive({
        url: 'admin/device-types',
        method: HTTP_METHODS.GET,
        params: {device_category_id, lang}
    })

export const requesAddDeviceTypeAdminApi = (data) =>
    fetcher.requestToReceive({
        url: 'admin/device-types',
        method: HTTP_METHODS.POST,
        data
    })

export const requesEditDeviceTypeAdminApi = (idType, data) =>
    fetcher.requestToReceive({
        url: `admin/device-types/${idType}`,
        method: HTTP_METHODS.PUT,
        data
    })

export const requestDeleteDeviceTypeAdminApi = (idType) =>
    fetcher.requestToReceive({
        url: `admin/device-types/${idType}`,
        method: HTTP_METHODS.DELETE,
    })

export const requestDevicesCategoryAdminApi = (lang) =>
    fetcher.requestToReceive({
        url: 'admin/device-categories',
        method: HTTP_METHODS.GET,
        params: {lang}
    })

export const requestAddDevicesCategoryAdminApi = (data) =>
    fetcher.requestToReceive({
        url: 'admin/device-categories',
        method: HTTP_METHODS.POST,
        data,
    })

export const requestEditDevicesCategoryAdminApi = (idCategory ,data) =>
    fetcher.requestToReceive({
        url: `admin/device-categories/${idCategory}`,
        method: HTTP_METHODS.PUT,
        data,
    })


export const requestDeleteDeviCategoryAdminApi = (idCategory) =>
    fetcher.requestToReceive({
        url: `admin/device-categories/${idCategory}`,
        method: HTTP_METHODS.DELETE,
    })

export const requestChangeNoteDeviceAdminApi = (id, data) =>
    fetcher.requestToReceive({
        url: `admin/devices/${id}/note`,
        method: HTTP_METHODS.PATCH,
        data
    })

export const requestChangeCountDeviceAdminApi = (id, data) =>
    fetcher.requestToReceive({
        url: `admin/devices/${id}/count`,
        method: HTTP_METHODS.PATCH,
        data
    })


export const requestAddDeviceSelectionAdminApi = (data) =>
    fetcher.requestToReceive({
        url: 'admin/device-selections',
        method: HTTP_METHODS.POST,
        data
    })

export const requestDeleteDeviceSelectionAdminApi = (idDeviceSelection) =>
    fetcher.requestToReceive({
        url: `admin/device-selections/${idDeviceSelection}`,
        method: HTTP_METHODS.DELETE
    })

export const requestDevicesSelcetionAdminApi = (categoryId, lang) =>
    fetcher.requestToReceive({
        url: `admin/devices?device_category_id=${categoryId}`,
        params: {lang},
        method: HTTP_METHODS.GET
    })

export const requestEditDeviceSelectionAdminApi = (id, data) =>
    fetcher.requestToReceive({
        url: `admin/device-selections/${id}`,
        method: HTTP_METHODS.PUT,
        data
    })

export const requestEditCountDeviceSelectionAdminApi = (id, data) =>
    fetcher.requestToReceive({
        url: `admin/device-selections/${id}/count`,
        method: HTTP_METHODS.PATCH,
        data,
    })