import { requestMarkersMeasurerApi } from 'utils'
import { markersLoadSuccess, error, finish } from './markersSlice'

export const requestMarkersMeasurer = (planId) => async (dispatch) => {
    try {
        const responce = await requestMarkersMeasurerApi(planId)
        dispatch(markersLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(markersLoadSuccess([]))
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}
