import { requestUserInfo } from 'redux/configStore/user'
import { userAdminLoadSuccess, error, finish, loading } from './userSlice'
import { requestEditUserProfileAdminApi, requestResetPasswordAdminApi, requestUserAdminApi } from 'utils'
import { notification } from 'antd'

export const requestUserAdmin = (id) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestUserAdminApi(id)
        dispatch(userAdminLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requestEditUserProfileAdmin = (userId, data) => async (dispatch) => {
    try {
        dispatch(loading())
        await requestEditUserProfileAdminApi(userId, data)
        dispatch(requestUserInfo())
    } catch (err) {
        dispatch(error({ error: err }))
        notification.error({
            message: 'Ошибка',
            description: err.response?.data?.message,
        })
    } finally {
        dispatch(finish())
    }
}

export const requestResetPasswordAdmin = (userId, data) => async (dispatch) => {
    try {
        dispatch(loading())
        await requestResetPasswordAdminApi(userId, data)
        dispatch(requestUserInfo())
    } catch (err) {
        dispatch(error({ error: err }))
        notification.error({
            message: 'Ошибка',
            description: err.response?.data?.message,
        })
    } finally {
        dispatch(finish())
    }
}