import { List, Typography, Input, Empty, Popover } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useContext } from 'react'

import { requestChangeCountDeviceAdmin, requestChangeNoteDeviceAdmin } from 'redux/configStore'
import { ReactComponent as InfoIcon } from 'images/infoIcon.svg'
import { ReactComponent as EditIcon } from 'images/edit.svg'
import { LanguageContext } from 'helpers'
import './style.scss'

export const ListDevice = ({ data, }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { language } = useContext(LanguageContext)

    const navigateEdit = (id) => {
        navigate(`/admin-edit-device/${id}`)
    }

    const onChangeNote = (e, id) => {
        const data = {
            note: e.target.value,
        }
        dispatch(requestChangeNoteDeviceAdmin(id, data))
    }

    const onChangeCount = (e, id) => {
        const data = {
            count: isNaN(parseInt(e.target.value)) === null ? 0: parseInt(e.target.value),
        }
        dispatch(requestChangeCountDeviceAdmin(id, data))
    }

    return (
        <div className="list-device">
            <div className="list-device__header">
                <Typography className="list-device__header__num">№</Typography>
                <Typography className="list-device__header__link">{language === 'ru' ? `Функция устройства`: `Device Type`}</Typography>
                <Typography className="list-device__header__manufacture">Производитель</Typography>
                <Typography className="list-device__header__manufacture">{language === 'ru' ? `Модель`: `Name`}</Typography>
                <Typography className="list-device__header__link">{language === 'ru' ? `Код устройства`: `Device Code`}</Typography>
                <Typography className="list-device__header__code">{language === 'ru' ? `Наличие на складе`: `Stock availability`}</Typography>
                <Typography className="list-device__header__link-device">{language === 'ru' ? `Ссылка на устройство`: `Link to the device`}</Typography>
                <Typography className="list-device__header__link">{language === 'ru' ? `Заметка`: `Note`}</Typography>
                <Typography className="list-device__header__link">{language === 'ru' ? `Продано`: `Sold`}</Typography>
            </div>
            <List
                className="list-device__list"
                dataSource={data}
                locale={{
                    emptyText: (
                        <Empty description={language === 'ru' ? `Нет данных для отображения`: `No data to display`} />
                    )
                }}
                renderItem={(item, index) => 
                    <List.Item key={index}>
                        <Typography className="list-device__list__number">{index + 1}</Typography>
                        <div className="list-device__list__title">
                            <EditIcon className="list-device__list__title__icon cursor" onClick={() => navigateEdit(item.id)}/>
                            <Typography className="list-device__list__title__text">
                                {item.device_type?.device_category?.title}
                            </Typography>
                        </div>
                        <Typography className="list-device__list__name">{item.manufacturer?.title}</Typography>
                        <Typography className="list-device__list__name">{item.title}</Typography>
                        <Typography className="list-device__list__code">{item.product_code}</Typography>
                        {!!item.count ?
                            <div className="list-device__list__count">
                                <Typography className="list-device__list__count__title">{language === 'ru' ? `В наличии`: `In stock`}</Typography>
                                <div className="list-device__list__count__col">
                                    <Input
                                        className="list-device__list__count__col_value"
                                        defaultValue={item.count}
                                        onChange={(e) => onChangeCount(e, item.id)}
                                    />
                                </div>
                            </div> :
                            <div className="list-device__list__count">
                                <Typography className="list-device__list__count-red">{language === 'ru' ? `Нет в наличии`: `Out of stock`}</Typography>
                                <div className="list-device__list__count__col">
                                        <Input
                                            className="list-device__list__count__col_value"
                                            value={item.count}
                                            onChange={(e) => onChangeCount(e, item.id)}
                                        />
                                </div>
                            </div>
                        }
                        <Link to={item.link} target='_blank'>
                            <Typography className="list-device__list__link">{item.link}</Typography>
                        </Link>
                        <Input
                            className="list-device__list__input"
                            placeholder={language === 'ru' ? `Введите текст`: `Enter the text`}
                            defaultValue={item.note}
                            onChange={(e) => onChangeNote(e, item.id)}
                        />
                        <Typography className="list-device__list__sales">{item.sold_count}</Typography>
                        <Popover
                            content={
                                <Typography className="list-device__list__popover__title">
                                    {item.description}
                                </Typography>}
                            trigger="click"
                            className="list-device__list__popover"
                        >
                            <InfoIcon className="list-device__list__info cursor"/>
                        </Popover>
                    </List.Item>
                }
            />
        </div>
    )
}