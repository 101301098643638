import axios from 'axios'
import { BASE_URL, HTTP_METHODS } from 'helpers'
  
const defaultConfig = {
    prefixURL: '/',
    baseURL: BASE_URL,
    method: HTTP_METHODS.GET,
    timeout: 30 * 1000,
}
  
export class Fetcher {
    constructor(config = defaultConfig) {
        const hostURL = config.baseURL || defaultConfig.baseURL
        const prefixURL = config.prefixURL || defaultConfig.prefixURL
  
        this.instance = axios.create({
            ...defaultConfig,
            ...config,
            baseURL: [prefixURL, hostURL].join(''),
        })
  
        this.instance.interceptors.request.use((config) => {
            const token = localStorage.getItem('token')
    
            if (!token) {
              return config
            }
    
            const headers = {
              Authorization: `Bearer ${token}`,
            }
    
            return { ...config, headers }
        })
  
        this.instance.interceptors.response.use(
            (response) => response,
            (error) => Promise.reject(error),
        )
    }
  
    handlerCatch = (e) => {
        const { response } = e
        const { status } = response
        if (response) {
            if (status === 401) {
                localStorage.clear()
            }
        }
  
      if (status === 400) {
        localStorage.clear()
      }
  
      throw e
    }
  
    request = (
      requestConfig
    ) => {
      return this.instance
        .request({
          ...requestConfig,
          baseURL: [
            requestConfig.prefixURL || defaultConfig.prefixURL,
            requestConfig.baseURL || defaultConfig.baseURL,
          ].join(''),
        })
        .then((resp) => resp)
        .catch((e) => this.handlerCatch(e))
    }
  
    requestToReceive = (
      requestConfig,
    ) => {
      return this.instance
        .request({
            ...requestConfig,
            baseURL: BASE_URL,
        })
        .then((resp) => resp)
        .catch((e) => this.handlerCatch(e))
    }
  }
  
  