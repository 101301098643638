import { addDeviceTypeAdmin, devicesTypeAdminLoadSuccess, error, finish, loading } from './devicesTypeSlice'
import { requesAddDeviceTypeAdminApi, requesEditDeviceTypeAdminApi, requestDeleteDeviceTypeAdminApi, requestDevicesTypeAdminApi } from 'utils'

export const requestDevicesTypeAdmin = (deviceCategoryId, lang) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestDevicesTypeAdminApi(deviceCategoryId, lang)
        dispatch(devicesTypeAdminLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requestDeleteDeviceTypeAdmin = (idType) => async (dispatch) => {
    try {
        await requestDeleteDeviceTypeAdminApi(idType)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestDevicesTypeAdmin())
    }
}

export const requesAddDeviceTypeAdmin = (data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requesAddDeviceTypeAdminApi(data)
        if(responce.data.data) {
            dispatch(addDeviceTypeAdmin(responce.data.data))
        }
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
        dispatch(requestDevicesTypeAdmin())
    }
}

export const requesEditDeviceTypeAdmin = (id, data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requesEditDeviceTypeAdminApi(id, data)
        if (responce.data.data) {
            dispatch(requestDevicesTypeAdmin())
        }
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
    }
}