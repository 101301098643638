import { useNavigate } from 'react-router-dom'
import { Button, Typography } from 'antd'
import { useContext } from 'react'

import { Modal } from 'components/_atoms'
import { LanguageContext } from 'helpers'
import './style.scss'

export const ModalSuccess = ({role, isModalOpen, handleOk, handleCancel, title, route}) => {
    const navigate = useNavigate()
    const { language } = useContext(LanguageContext)

    const navigateToHome = () => {
        role === 'measurer' ? handleCancel():
        navigate(route)
    }
    
    return (
        <Modal
            isModalOpen={isModalOpen}
            handleOk={handleOk}
            handleCancel={handleCancel}
            component={(
                <div className="modal-content-success">
                    <div className="modal-content-success__icon"></div>
                    <Typography className="modal-content-success__title">{title}</Typography>
                    <div className="btn-layout">
                        <Button className="layout-save__btn" onClick={navigateToHome}>
                            <Typography className="layout-save__btn_title">{language === 'ru'? `Вернуться`: `Back`}</Typography>
                        </Button>
                    </div>
                </div>
            )}
        />
    )
}