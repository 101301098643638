import { Navigate, Route, Routes } from 'react-router-dom'

import { routes, InnerContext, PublicRoutes, ProtectedRoutes } from 'router'
import { HomePage, SignInPage, PickerSelectPage, MeasurerLayoutPage, MeasurerAddClientPage,
    MeasurerEditClientPage, AdminOrdersPage, AdminLayoutPage, AdminCatalogPage, AdminEmployeePage,
    AdminAddDevicePage, AdminEditDevicePage, AdminAddEmployeePage, AdminEditProfilePage,
    AdminEditEmployeePage, AdminResetPasswordPage, AdminCatalogSettingsPage, AdminEditClientPage, AdminAddClientPage,
} from 'components/_pages'

export const AppRoutes = () => (
    <Routes>
        <Route path={routes.default} element={<ProtectedRoutes/>}>
            <Route path={routes.default} element={<InnerContext />}>
                <Route path={routes.default} element={<Navigate replace to={routes.home}/>} />
                <Route path={routes.home} element={<HomePage />} />

                <Route path={routes.pickerSelect} element={<PickerSelectPage/>}/>
                
                <Route path={routes.clientAdd} element={<MeasurerAddClientPage/>}/>
                <Route path={routes.measurerLayout} element={<MeasurerLayoutPage/>}/>
                <Route path={routes.editClient} element={<MeasurerEditClientPage />} />
                
                <Route path={routes.orders} element={<AdminOrdersPage/>}/>
                <Route path={routes.catalog} element={<AdminCatalogPage/>}/>
                <Route path={routes.employee} element={<AdminEmployeePage/>}/>
                <Route path={routes.adminLayout} element={<AdminLayoutPage/>}/>
                <Route path={routes.addDevice} element={<AdminAddDevicePage/>}/>
                <Route path={routes.adminAddClient} element={<AdminAddClientPage />}/>
                <Route path={routes.editProfile} element={<AdminEditProfilePage/>}/>
                <Route path={routes.addEmployee} element={<AdminAddEmployeePage/>}/>
                <Route path={routes.adminEditDevice} element={<AdminEditDevicePage/>}/>
                <Route path={routes.adminClientEdit} element={<AdminEditClientPage />}/>
                <Route path={routes.resetPassword} element={<AdminResetPasswordPage/>}/>
                <Route path={routes.adminEditEmployee} element={<AdminEditEmployeePage/>}/>
                <Route path={routes.catalogSettings} element={<AdminCatalogSettingsPage/>}/>
            </Route>
        </Route>
        <Route path={routes.login} element={<PublicRoutes/>}>
            <Route path={routes.login} element={<SignInPage/>} />
        </Route>
    </Routes>
)