import { requestAddFloorPlanMeasurer, requestEditFloorPlantMeasurer, requestFloorPlanMeasurer, requestUpdateFloorPlanDeviceCategoryCommentApi } from 'utils'
import { planLoadSuccess, error, finish, loading, planAdd } from './floorPlanSlice'
import { requestMarkersMeasurer } from '../markers'

export const requestPLanMeasurer = (id) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestFloorPlanMeasurer(id)
        dispatch(planLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requestPLanAdd = (id, data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestAddFloorPlanMeasurer(id, data)
        if(responce.data.data) {
            dispatch(planAdd(responce.data.data))
        }
        dispatch(requestPLanMeasurer(id))
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
    }
}

export const requestPLanEdit = (idClient, id, data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestEditFloorPlantMeasurer(idClient, id, data)
        if(responce.data.data) {
            dispatch(planAdd(responce.data.data))
        }
        dispatch(requestPLanMeasurer(id))
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        requestPLanMeasurer(idClient)
    }
}

export const requestPlanCommentEdit = (categoryId, planId, data) => async (dispatch) => {
    try {
        dispatch(loading())
        await requestUpdateFloorPlanDeviceCategoryCommentApi(categoryId, data)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestMarkersMeasurer(planId))
    }
}