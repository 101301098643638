
import { ReactComponent as LogoIcon } from 'images/logoIcon.svg'
import './style.scss'

export const Loader = () => {
    return (
        <div className="loader-container">
            <LogoIcon />
            <div className="loader"></div>
        </div>  
    )
}