import { useState, useEffect, useLayoutEffect, useContext } from 'react'
import { Typography, Input, Form, Button, Select, Spin, notification } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { requestDeviceAdmin, requestDevicesTypeAdmin, requestDevicesCategoryAdmin, requestManufacturerAdmin, requestProtocolAdmin } from 'redux/configStore'
import { requestDeleteDeviceAdmin, requestEditDeviceAdmin } from 'redux/configStore/admin/devices/action'
import { ADMIN_DEVICE_FORM_REQUIRED_FIELDS } from 'constants/data/data'
import { ReactComponent as IconDelete } from 'images/deleteIcon.svg'
import { ModalSuccess } from 'components/_molecules'
import { BtnBack, Modal } from 'components/_atoms'
import { LanguageContext } from 'helpers'
import { routes } from 'router'
import { deviceAdminLoadSuccess } from 'redux/configStore/admin/device/deviceSlice'
import TextArea from 'antd/es/input/TextArea'

export const AdminEditDevice = () => {
    const params = useParams()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const idDevice = params.id

    const { language } = useContext(LanguageContext)
    
    const [selectedTypes, setSelectedTypes] = useState()

    const devicesType = useSelector((state) => state.devicesTypeAdmin.devicesTypeAdmin)
    const devicesTypeLoading = useSelector((state) => state.devicesTypeAdmin.isLoading)

    const devicesCategory = useSelector((state) => state.devicesCategoryAdmin.devicesCategoryAdmin)
    const devicesCategoryLoading = useSelector((state) => state.devicesCategoryAdmin.isLoading)

    const device = useSelector((state) => state.deviceAdmin.deviceAdmin)
    const deviceLoading = useSelector((state) => state.deviceAdmin.isLoading)
    const devicesError = useSelector((state) => state.devicesAdmin.error)

    const devicesLoading = useSelector((state) => state.devicesAdmin.isLoading)

    const manufacturers = useSelector((state) => state.manufacturerAdmin.manufacturerAdmin)
    const manufacturersLoading = useSelector((state) => state.manufacturerAdmin.isLoading)
    
    const protocols = useSelector((state) => state.protocolAdmin.protocolAdmin)
    const protocolsLoading = useSelector((state) => state.protocolAdmin.isLoading)

    const [searchValue, setSearchValue] = useState('')
    const [deviceType, setDeviceType] = useState('')
    const [protocolsId, setProtocolsId] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const [isModalOpenDelete, setIsModalOpenDelete] = useState(false)

    const dataDevicesCategory = devicesCategory.map(obj => ({
        value: obj.id,
        label: obj.title,
    }))

    const dataManufacturers = manufacturers.map(obj => ({
        value: obj.id,
        label: obj.title,
    }))

    const dataProtocol = protocols.map(obj => ({
        value: obj.id,
        label: obj.title,
    }))

    const filteredOptions = dataProtocol
        .filter((option) =>
            option.label.toLowerCase().includes(searchValue.toLowerCase())
        )
        .sort((a, b) => a.label.localeCompare(b.label))

    const handleProtocolChange = (value) => {
        setProtocolsId(value.reduce((acc, protocolId) => {
            acc[protocolId] = true
            return acc
        }, {}))
    }

    const handleSearchProtocol = (selectedValue, option) => {
        const selectedProtocolId = option.key
        const updatedProtocolsId = {
            ...protocolsId,
            [selectedProtocolId]: true
        }
        handleProtocolChange(Object.keys(updatedProtocolsId))
        setDropdownVisible(false)
        setSearchValue('')
    }

    const handleProtocolSelect = (selectedValue, option) => {
        handleProtocolChange(selectedValue)
        setDropdownVisible(false)
        setSearchValue('')
    }

    const handleDeviceTypeChange = (value) => {
        setDeviceType(value)
    }

    const onFinish = (values) => {
        const selectedProtocols = Object.keys(protocolsId)
        const data = {
            device_type_id: deviceType,
            product_code: values.product_code,
            title: values.title,
            count: values.count,
            link: values.link,
            description: values.description,
            manufacturer_id: values.manufacturer,
            protocols: selectedProtocols,
        }
        if (idDevice !== null) {
            dispatch(requestEditDeviceAdmin(idDevice, data))
        }
        showModal()
    }

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        form.resetFields()
        setIsModalOpen(false)
        navigate(routes.catalog)
    }

    const showModalDelete = () => {
        setIsModalOpenDelete(true)
    }

    const handleOkDelete = () => {
        setIsModalOpenDelete(false)
        navigate(routes.catalog)
    }

    const handleCancelDelete = () => {
        setIsModalOpenDelete(false)
    }

    useEffect(() => {
        dispatch(requestDevicesTypeAdmin('', language))
        dispatch(requestDevicesCategoryAdmin(language))
        dispatch(requestManufacturerAdmin(language))
        dispatch(requestProtocolAdmin(language))
        if (idDevice !== null) {
            dispatch(requestDeviceAdmin(idDevice))
        }
    }, [dispatch, idDevice, language])

    const handleDeviceCategoryChange = (categoryId) => {
        const types = devicesType.filter(type => type.device_category.id === categoryId)
        setSelectedTypes(types.map(type => ({
            label: type.title,
            value: type.id
        })))
        setDeviceType(null)
    }

    useEffect(() => {
        const categoryId = device.device_type?.device_category?.id
        const types = devicesType.filter(type => type.device_category.id === categoryId)
        setSelectedTypes(types.map(type => ({
            label: type.title,
            value: type.id
        })))
    }, [devicesType, device.device_type?.device_category?.id])

    const deleteDevice = () => {
        if (idDevice !== undefined) {
            dispatch(requestDeleteDeviceAdmin(idDevice))
        }
        notification.success({
            message: 'Устройство удалено',
            description: `удалено ${device.title}`,
        })
        navigate(routes.catalog)
    }

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)

    const onValuesChange = (changedValues, allValues) => {
        const isSubmitDisabled = !ADMIN_DEVICE_FORM_REQUIRED_FIELDS.every(name => !!form.getFieldValue(name))
            || !!form.getFieldsError().filter(({ errors }) => errors.length).length
        setIsSubmitDisabled(isSubmitDisabled)
    }

    useEffect(() => () => {
        dispatch(deviceAdminLoadSuccess({}))
    }, [])

    useLayoutEffect(() => {
        setDeviceType(device.device_type?.id)
        form.setFieldsValue({
            funcDevice: device.device_type?.device_category?.id,
            typeDevice: device.device_type?.id,
            product_code: device.product_code,
            title: device.title,
            count: device.count,
            link: device.link,
            description: device.description,
            manufacturer_id: device.manufacturer?.id,
            protocols: device.protocols?.map(item => item.id),
         })
    }, [form, deviceLoading, device])

    const [searchValueManufacturers, setSearchValueManufacturers] = useState('');
    const filteredManufacturers = dataManufacturers.filter(manufacturer =>
        manufacturer.label.toLowerCase().includes(searchValueManufacturers.toLowerCase())
    );

    const handleSearch = value => {
        setSearchValueManufacturers(value);
    };

    return (
        <>
            <div className="wrapper-admin">
                <BtnBack route={routes.catalog} />
                <Typography className="add-device_title">
                    {language === 'ru' ? `Устройство`: `Device`}
                </Typography>
                <IconDelete className="add-device-delete cursor" onClick={showModalDelete}/>
            </div>
            {deviceLoading ? <Spin className="spin-list" /> :
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                    className="add-emplyee__form"
                    onValuesChange={onValuesChange}
                >
                    <Form.Item
                        name="funcDevice"
                        label={language === 'ru' ? `Функция устройства *`: `Device function *`}
                        key={`funcDevice_${device.id}`}
                    >
                        {devicesCategoryLoading ? <Spin className="spin"/> :
                            <Select
                                defaultValue={device.device_type?.device_category?.id}
                                className="add-device_select"
                                options={dataDevicesCategory}
                                onChange={handleDeviceCategoryChange}
                            />
                        }
                    </Form.Item>
                    <Form.Item
                        name="typeDevice"
                        label={language === 'ru' ? `Тип устройства *`: `Device Type *`}
                        key={`typeDevice_${device.id}`}
                    >
                        {devicesTypeLoading ? <Spin className="spin"/> :
                            <Select
                                className="add-device_select"
                                value={deviceType}
                                options={selectedTypes}
                                onChange={handleDeviceTypeChange}
                            />
                        }
                    </Form.Item>
                    <Form.Item
                        name="manufacturer"
                        label={language === 'ru' ? `Производитель *`: `Manufacturer *`}
                        key={`manufacturer_${device.id}`}
                    >
                        {manufacturersLoading ? <Spin className="spin"/> :
                            <Select
                                className="add-device_select"
                                defaultValue={device.manufacturer?.id}
                                showSearch
                                optionFilterProp="children"
                                filterOption={false}
                                onSearch={handleSearch}
                            >
                                {filteredManufacturers.map(manufacturer => (
                                <Select.Option key={manufacturer.value} value={manufacturer.value}>
                                    {manufacturer.label}
                                </Select.Option>
                                ))}
                            </Select>
                        }
                    </Form.Item>
                    <Form.Item
                        name="protocols"
                        label={language === 'ru' ? `Протокол`: `Protocol *`}
                        key={`protocol_${device.id}`}
                    >
                        {protocolsLoading ? <Spin className="spin"/> :
                            <Select
                                className="add-device_select"
                                options={filteredOptions}
                                onChange={handleProtocolChange}
                                mode="multiple"
                                optionLabelProp="label"
                                showSearch
                                value={protocolsId}
                                open={dropdownVisible}
                                onDropdownVisibleChange={setDropdownVisible}
                                onSearch={handleSearchProtocol}
                                filterOption={false}
                                onSelect={handleProtocolSelect}
                            >
                                {filteredOptions.map((option) => (
                                <Select.Option key={option.value} value={option.value} label={option.label}>
                                    <Typography.Text>{option.label}</Typography.Text>
                                </Select.Option>
                                ))}
                            </Select>
                        }
                    </Form.Item>
                    <Form.Item
                        name="product_code"
                        label={language === 'ru' ? `Код устройства *`: `Product code *`}
                        key={`product_code_${device.id}`}
                        rules={[{ required: true, message: `${language === 'ru' ? `Введите код устройства`: `Enter the product code`}` }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="title"
                        label={language === 'ru' ? `Модель *`: `Name *`}
                        rules={[{ required: true, message: `${language === 'ru' ? `Введите название`: `Enter a name`}` }]}
                        key={`title_${device.id}`}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="count"
                        key={`count_${device.id}`}
                        label={language === 'ru' ? `Количество на складе *`: `Quantity in stock *`}
                        rules={[{ required: true, message: `${language === 'ru' ? `Введите количество`: `Enter the quantity`}` }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="link"
                        key={`link_${device.id}`}
                        label={language === 'ru' ? `Ссылка на устройство *`: `Link to the device *`}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label={language === 'ru' ? `Описание *`: `Description *`}
                        key={`description_${device.id}`}
                    >
                        <TextArea className="add-emplyee__form__note" placeholder={language === 'ru' ? `Введите текст`: `Enter the text`}/>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        <Button
                            className="client-add__form__btn"
                            type="primary"
                            htmlType="submit"
                        >
                            <Typography className="client-add__form__btn_title">{language === 'ru' ? `Сохранить`: `Save`}</Typography>
                        </Button>
                    </Form.Item>
                </Form>
            }
            {!devicesLoading && !devicesError &&
                <ModalSuccess
                    isModalOpen={isModalOpen}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    title={language === 'ru' ? `Устройство успешно изменено`: `The device has been successfully changed`}
                    route={routes.catalog}
                />
            }
            <Modal
                isModalOpen={isModalOpenDelete}
                handleOk={handleOkDelete}
                handleCancel={handleCancelDelete}
                component={(
                    <>
                        <Typography className="delete-warning__title">{`Вы уверены что хотите удалить устройство: ${device.title}?`}</Typography>
                        <div className="delete-warning_btn-group">
                            <Button className="delete-warning_btn-group__ok" onClick={deleteDevice}>
                                <Typography className="delete-warning_btn-group__ok__title">Да</Typography>
                            </Button>
                            <Button className="delete-warning_btn-group__cancel" onClick={handleCancelDelete}>
                                <Typography className="delete-warning_btn-group__cancel__title">Нет</Typography>
                            </Button>
                        </div>
                    </>
                )}
            />
        </>
    )
}
