import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_VIDEO_Admin } from './constant'

const initialState = {
    videoAdmin: {},
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const videoAdminSlice = createSlice({
    name: MODULE_NAME_VIDEO_Admin,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        videoAdminLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.videoAdmin = action.payload
        },
        videoAdminAdd(state, action) {
            state.isLoaded = true
            state.videoAdmin = { ...state.videoAdmin, ...action.payload }
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default videoAdminSlice.reducer
export const { loading, error, videoAdminLoadSuccess, finish, videoAdminAdd } = videoAdminSlice.actions