import { requestClientMeasurerApi, requestClientNotReadyMeasurerApi, requestClientReadyMeasurerApi } from 'utils'
import { clientMeasurerLoadSuccess, error, finish, loading } from './clientMeasurerSlice'
import { requestPLanMeasurer } from '../floorPlan'
import { requestMarkersMeasurer } from '../markers'
import { notification } from 'antd'

export const requestClientMeasurer = (id) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestClientMeasurerApi(id)
        dispatch(clientMeasurerLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requestClientReadyMeasurer = (id, planId) => async (dispatch) => {
    try {
        dispatch(loading())
        await requestClientReadyMeasurerApi(id)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestClientMeasurer(id))
        dispatch(requestPLanMeasurer(id))
        dispatch(requestMarkersMeasurer(planId))
    }
}

export const requestClientNotReadyMeasurer = (id, planId) => async (dispatch) => {
    try {
        dispatch(loading())
        await requestClientNotReadyMeasurerApi(id)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestPLanMeasurer(id))
        dispatch(requestClientMeasurer(id))
        dispatch(requestMarkersMeasurer(planId))
        notification.success({
            message: 'Вы можете редактировать замер',
        })
    }
}
