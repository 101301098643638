import { error, finish, loading, rolesAdminLoadSuccess } from './rolesSlice'
import { requestRolesAdminApi } from 'utils'

export const requestRolesAdmin = (language) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestRolesAdminApi(language)
        dispatch(rolesAdminLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}
