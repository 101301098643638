import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { notification } from 'antd'
import { useState } from 'react'

import { requestAddImageAdmin, requestAddVideoAdmin, requestImageAddMeasurer, requestVideoAddMeasurer } from 'redux/configStore'
import { ReactComponent as PhotoDisable } from 'images/photoDisableIcon.svg'
import { ReactComponent as VideoDisable } from 'images/videoDisableIcon.svg'
import { ReactComponent as PhotoIcon } from 'images/photoIcon.svg'
import { ReactComponent as VideoIcon } from 'images/videoIcon.svg'
import './style.scss'

export const ImageVideoUploader = ({ role, image, errorPlan, disable }) => {
    const params = useParams();
    const dispatch = useDispatch();
  
    const id = params.id;
  
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
  
    const handleImageChange = (event) => {
      const files = event.target.files;
      const updatedImages = [...selectedImages];
  
      for (let i = 0; i < files.length; i++) {
        const selectedFile = files[i];
        const formData = new FormData();
  
        if (selectedFile) {
          formData.append('path', selectedFile, selectedFile.name);
          formData.append('client_id', id);
  
          const reader = new FileReader();
          reader.readAsDataURL(selectedFile);
  
          reader.onloadend = () => {
            const data = {
              path: reader.result.split(',')[1],
            };
            formData.append('data', JSON.stringify(data));
            if (role === 'admin') {
              dispatch(requestAddImageAdmin(id, formData));
            } else {
              dispatch(requestImageAddMeasurer(id, formData));
            }
          };
  
          updatedImages.push(selectedFile);
        }
      }
  
      setSelectedImages(updatedImages);
    };
  
    const handleVideoChange = (event) => {
      const files = event.target.files;
      const updatedVideos = [...selectedVideos];
    
      for (let i = 0; i < files.length; i++) {
        const selectedFile = files[i];
    
        if (selectedFile) {
          if (selectedFile.type.indexOf('video/') !== 0) {
            alert('Выбранный файл не является видео.');
            return;
          }
    
          if (!id || typeof id !== 'string') {
            alert('Не удалось получить id клиента.');
            return;
          }
    
          const videoElement = document.createElement('video');
          videoElement.preload = 'metadata';
          videoElement.onloadedmetadata = () => {
            if (videoElement.duration > 60) {
              notification.error({
                message: 'Ошибка',
                description: 'Продолжительность видео превышает 1 минуту.'
              });
            } else {
              const formData = new FormData();
              formData.append('path', selectedFile, selectedFile.name);
              formData.append('client_id', id);
    
              const reader = new FileReader();
              reader.readAsDataURL(selectedFile);
    
              reader.onloadend = () => {
                const data = {
                  path: reader.result.split(',')[1],
                };
    
                formData.append('data', JSON.stringify(data));
                if (role === 'admin') {
                  dispatch(requestAddVideoAdmin(id, formData));
                } else {
                  dispatch(requestVideoAddMeasurer(id, formData));
                }
              };
    
              updatedVideos.push(selectedFile);
            }
          };
          videoElement.src = URL.createObjectURL(selectedFile);
        }
      }
    
      setSelectedVideos(updatedVideos);
    };
    
  
    return (
      <div className="img-video-uploader no-printme">
        {(image && !errorPlan) && (
          <>
            {!disable ? (
              <form className="img-array" encType="multipart/form-data">
                <label htmlFor="img-array-button">
                  <PhotoIcon className="img-array__icon" />
                </label>
                <input
                  multiple
                  type="file"
                  accept="image/*"
                  id="img-array-button"
                  className="btn-group-uploader__btn"
                  onChange={handleImageChange}
                />
              </form>
            ) : (
              <PhotoDisable className="img-array__icon-diable" />
            )}
            {!disable ? (
              <form className="video-array" encType="multipart/form-data">
                <label htmlFor="video-array-button">
                  <VideoIcon className="video-array__icon" />
                </label>
                <input
                  multiple
                  type="file"
                  accept="video/*"
                  id="video-array-button"
                  className="btn-group-uploader__btn"
                  onChange={handleVideoChange}
                />
              </form>
            ) : (
              <VideoDisable className="video-array__icon-diable" />
            )}
          </>
        )}
      </div>
    );
  };