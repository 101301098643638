import { useContext } from 'react'
import { Typography } from 'antd'

import { SettingsMenu, SettingsMenuManufacturer, SettingsMenuProtocol } from 'components/_molecules'
import { BtnBack } from 'components/_atoms'
import { LanguageContext } from 'helpers'
import { routes } from 'router'
import './style.scss'

export const AdminCatalogSettings = () => {
    const { language } = useContext(LanguageContext)

    return (
        <>
            <div className="wrapper-admin">
                <div className="catalog-settings">
                    <BtnBack route={routes.catalog}/>
                    <Typography className="catalog-settings__title">{language === 'ru' ? `Настройки каталога`: `Catalog Settings`}</Typography>
                </div>
            </div>
            <SettingsMenu />
            <SettingsMenuProtocol />
            <SettingsMenuManufacturer />
        </>
    )
}