import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_DEVICES_SELECTION_ADMIN } from './constant'

const initialState = {
    deviceSelectionAdmin: [],
    isLoading: false,
    isLoaded: false,
    error: null,
    deviceSelectionId: null,
}

export const deviceSelectionSlice = createSlice({
    name: MODULE_NAME_DEVICES_SELECTION_ADMIN,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        deviceSelectionSliceLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.deviceSelectionAdmin = action.payload.data
        },
        deviceSelectionAdminAddAdmin(state, action) {
            state.isLoaded = true
            state.deviceSelectionAdmin = [ ...state.deviceSelectionAdmin, ...action.payload ]
        },
        finish(state) {
            state.isLoading = false
        },
        setDeviceSelectionId(state, action) {
            state.deviceSelectionId = action.payload
        }
    }
})

export default deviceSelectionSlice.reducer
export const { loading, error, deviceSelectionSliceLoadSuccess, deviceSelectionAdminAddAdmin, finish, setDeviceSelectionId } = deviceSelectionSlice.actions
