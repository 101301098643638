import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_USER } from './constant'

const initialState = {
    user: {},
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const userSlice = createSlice({
    name: MODULE_NAME_USER,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        userLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.user = action.payload
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default userSlice.reducer
export const { loading, error, userLoadSuccess, finish } = userSlice.actions