import { useEffect, useLayoutEffect, useState, useContext } from 'react'
import { Form, Button, Typography, Input, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { requestEditUserProfileAdmin, requestUserInfo } from 'redux/configStore'
import { USER_PROFILE_FORM_REQUIRED_FIELDS } from 'constants'
import { ModalSuccess } from 'components/_molecules'
import { BtnBack } from 'components/_atoms'
import { LanguageContext } from 'helpers'
import { routes } from 'router'
import './style.scss'

export const AdminEditProfile = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { language } = useContext(LanguageContext)

    const user = useSelector((state) => state.user.user)
    const userLoading = useSelector((state) => state.userAdmin.isLoading)
    const userError = useSelector((state) => state.userAdmin.error)

    const [phone, setPhone] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)

    const onFinish = (values) => {
        const data = {
            firstname: values.firstname,
            surname: values.surname,
            login: values.login,
            phone: values.phone,
            email: values.email,
        }
        dispatch(requestEditUserProfileAdmin(user.id, data))
        showModal()
    }

    const handlePhoneChange = (value, country) => {
        setPhone(value)
    }

    const navigateReset = () => {
        navigate(routes.resetPassword)
    }

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        form.resetFields()
        setIsModalOpen(false)
        navigate(routes.home)
    }

    useEffect(() => {
        dispatch(requestUserInfo())
    }, [dispatch])

    useLayoutEffect(() => {
        form.setFieldsValue({
            firstname: user.firstname,
            surname: user.surname,
            login: user.login,
            phone: user.phone,
            email: user.email,
        })
    }, [form, userLoading, user])

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)

    const onValuesChange = (changedValues, allValues) => {
        const isSubmitDisabled = !USER_PROFILE_FORM_REQUIRED_FIELDS.every(name => !!form.getFieldValue(name))
            || !!form.getFieldsError().filter(({ errors }) => errors.length).length
        setIsSubmitDisabled(isSubmitDisabled)
    }

    return (
        <>
            <div className="wrapper-admin">
                <BtnBack route={routes.home} />
                <Typography className="edit-profile__title">{language === 'ru' ? `Редактирование профиля`: `Editing a profile`}</Typography>
            </div>
            {userLoading ? <Spin className="spin-list"/>:
                <div className="add-emplyee__form">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off"
                        onValuesChange={onValuesChange}
                    >
                        <Form.Item
                            name="firstname"
                            label={language === 'ru' ? `Имя *`: `Name *`}
                            key={`firstname_${user.id}`}
                            rules={[{ required: true, message: `${language === 'ru' ? `Введите имя`: `Enter your name`}` }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="surname"
                            label={language === 'ru' ? `Фамилия *`: `Surname *`}
                            key={`surname_${user.id}`}
                            rules={[{ required: true, message: `${language === 'ru' ? `Введите фамилию`: `Enter your surname`}` }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="login"
                            label={language === 'ru' ? `Логин *`: `Login *`}
                            key={`login_${user.id}`}
                            rules={[{ required: true, message: `${language === 'ru' ? `Введите логин`: `Enter your username`}` }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            label={language === 'ru' ? `Номер телефона *`: `Phone number *`}
                            key={`phone_${user.id}`}
                            rules={[{ required: true, message: `${language === 'ru' ? `Введите номер телефона`: `Enter your phone number`}` }]}
                        >
                            <PhoneInput
                                country={'ge'}
                                value={phone}
                                onChange={handlePhoneChange}
                                inputComponent={Input}
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Email *"
                            key={`email_${user.id}`}
                            rules={[{ required: true, message: `${language === 'ru' ? `Введите email`: `Enter email`}` }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item shouldUpdate>
                            <Button
                                className="client-add__form__btn"
                                type="primary"
                                htmlType="submit"
                                disabled={isSubmitDisabled || userLoading}
                            >
                                <Typography className="client-add__form__btn_title">{language === 'ru' ? `Сохранить`: `Save`}</Typography>
                            </Button>
                        </Form.Item>
                    </Form>
                    <Button className="edit-profile__reset" onClick={navigateReset}>
                        <Typography className="btn-group__send_btn_title">
                            {language === 'ru' ? `Изменить пароль`: `Change password`}
                        </Typography>
                    </Button>
                </div>
            }
            {!userLoading && !userError && (
                <ModalSuccess
                    isModalOpen={isModalOpen}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    title={language === 'ru' ? `Профиль изменён`: `Profile changed`}
                    route={routes.home}
                />
            )}
        </>
    )
}