import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import { AppRoutes } from 'router'
import { store } from 'redux/store'
import './index.css'

import * as pdfjsLib from 'pdfjs-dist'
import PDFJSWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry'
import { LanguageProvider } from 'helpers'
pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorker

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <LanguageProvider>
        <AppRoutes />
      </LanguageProvider>
    </Provider>
  </BrowserRouter>
);