import { Typography, Spin, Input, Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useContext, useLayoutEffect } from 'react'

import { ReactComponent as IconDelete } from 'images/deleteIcon.svg'
import { ReactComponent as IconEdit } from 'images/editIcon.svg'
import { ReactComponent as IconAdd } from 'images/addIcon.svg'
import { ButtonPrimary, Modal } from 'components/_atoms'
import { LanguageContext } from 'helpers'
import {
    requestProtocolAdmin,
    requestDeleteProtocolAdmin,
    requesEditProtocolAdmin,
    requesAddProtocolAdmin,
} from 'redux/configStore'
import './style.scss'
import { ADMIN_SETTINGS_FORM_REQUIRED_FIELDS } from 'constants/data/data'

export const SettingsMenuProtocol = () => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const { language } = useContext(LanguageContext)

    const protocols = useSelector((state) => state.protocolAdmin.protocolAdmin)
    const protocolsLoading = useSelector((state) => state.protocolAdmin.isLoading)

    const [title, setTitle] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [btnTitle, setBtnTitle] = useState('')
    const [formLabel, setFormLabel] = useState('')
    const [categoryId, setCategoryId] = useState(null)
    
    const handleAddProtocol = () => {
        setBtnTitle(`${language === 'ru' ? `Добавить протокол`: `Add a protocol`}`)
        setFormLabel(`${language === 'ru' ? `Введите протокол`: `Enter the protocol`}`)
        setCategoryId(null)
        setTitle('')
        showModal()
    }

    const handleChangeProtocol = (protocolId, protocolTitle) => {
        setBtnTitle(`${language === 'ru' ? `Изменить протокол`: `Change the protocol`}`)
        setFormLabel(`${language === 'ru' ? `Изменить протокол`: `Change the protocol`}`)
        setCategoryId(protocolId)
        setTitle(protocolTitle)
        showModal()
    }

    const handleDeleteProtocol = (idProtocol) => {
        dispatch(requestDeleteProtocolAdmin(idProtocol))
    }

    const onFinish = (values) => {
        if(formLabel === 'Введите протокол' || formLabel === 'Enter the protocol') {
            const data = {
                title: values.title
            }
            dispatch(requesAddProtocolAdmin(data))
        }
        if(btnTitle === 'Изменить протокол' || btnTitle === 'Change the protocol') {
            const data = {
                title: values.title
            }
            dispatch(requesEditProtocolAdmin(categoryId, data))
        }
        form.resetFields()
        handleCancel()
    } 

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    const onValuesChange = (changedValues, allValues) => {
        const isSubmitDisabled = !ADMIN_SETTINGS_FORM_REQUIRED_FIELDS.every(name => !!form.getFieldValue(name))
            || !!form.getFieldsError().filter(({ errors }) => errors.length).length
        setIsSubmitDisabled(isSubmitDisabled)
    }

    useEffect(() => {
        dispatch(requestProtocolAdmin(language))
    }, [language])

    useLayoutEffect(() => {
        if (categoryId !== null) {
            form.setFieldsValue({
                title: title
             })
        } else {
            form.setFieldsValue({
                title: null
             })
        }
    }, [form, categoryId, title])

    return (
        <div className="settings-menu-protocol">
            <div className="settings-menu__header">
                <Typography className="settings-menu__header__title">{language === 'ru' ? `Настройки протоколов`: `Protocol Settings`}</Typography>
                <IconAdd className="settings-menu__header__icon" onClick={handleAddProtocol}/>
            </div>
            {protocolsLoading ? <Spin className="spin-list"/> :
                <>
                    {protocols.map((protocol) => 
                            <Typography className="settings-menu-protocol__item-title">
                                <IconEdit
                                    className="settings-menu__icon-edit"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleChangeProtocol(protocol.id, protocol.title)
                                    }}
                                />
                                <IconDelete className="settings-menu__icon-delete"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleDeleteProtocol(protocol.id)
                                    }}
                                />
                                {protocol.title}
                            </Typography>
                    )}
                </>
            }
            <Modal
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                component={
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off" className="settings-menu__modal"
                        onValuesChange={onValuesChange}
                    >
                        <Form.Item
                            name="title"
                            label={formLabel}
                            key={`title_${categoryId}`}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <ButtonPrimary text={btnTitle} />
                        </Form.Item>
                    </Form>
                }
            />
        </div>
    )
}