import { HTTP_METHODS } from 'helpers'
import { Fetcher } from 'service'

const fetcher = new Fetcher()

export const requestFloorPlanMeasurer = (id) =>
    fetcher.requestToReceive({
        url: `measurer/clients/${id}/floor-plan`,
        method: HTTP_METHODS.GET
    })

export const requestAddFloorPlanMeasurer = (id, data) =>
    fetcher.requestToReceive({
        url: `measurer/clients/${id}/floor-plan`,
        method: HTTP_METHODS.POST,
        data
    })

export const requestEditFloorPlantMeasurer = (idClient, id, data) =>
    fetcher.requestToReceive({
        url: `measurer/clients/${idClient}/floor-plan/${id}?_method=PUT`,
        method: HTTP_METHODS.POST,
        data
    })

export const requestUpdateFloorPlanDeviceCategoryCommentApi = (categoryId, data) =>
    fetcher.requestToReceive({
        url: `measurer/comments/${categoryId}`,
        method: HTTP_METHODS.PATCH,
        data
    })