import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useContext } from 'react'
import { Spin, Typography } from 'antd'

import { requestClientsInfoPicker } from 'redux/configStore'
import { ListClient } from 'components/_molecules'
import { LanguageContext } from 'helpers'

export const PickerHome = () => {
    const dispatch = useDispatch()

    const { language } = useContext(LanguageContext)
    const clients = useSelector((state) => state.clients.clients)
    const loading = useSelector((state) => state.clients.isLoading)

    useEffect(() => {
        dispatch(requestClientsInfoPicker(language))
    }, [dispatch, language])

    return (
        <>
            {loading ? <Spin className="spin-list" /> :
                <>
                    <div className="wrapper__content">
                        <Typography className="wrapper__content_title">{language === 'ru' ? `Клиенты`: `Clients`}</Typography>
                    </div>
                <ListClient link='picker-select' data={clients} />
                </>
            }
        </>
    )
}