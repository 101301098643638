import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_IMAGE_ADMIN } from './constant'

const initialState = {
    imageAdmin: {},
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const imageAdminSlice = createSlice({
    name: MODULE_NAME_IMAGE_ADMIN,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        imageAdminLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.imageAdmin = action.payload
        },
        imageAdminAdd(state, action) {
            state.isLoaded = true
            state.imageAdmin = { ...state.imageAdmin, ...action.payload }
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default imageAdminSlice.reducer
export const { loading, error, imageAdminLoadSuccess, finish, imageAdminAdd } = imageAdminSlice.actions