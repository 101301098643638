import { deviceAdminLoadSuccess, error, finish, loading } from './deviceSlice'
import { requestDeviceAdminApi } from 'utils'

export const requestDeviceAdmin = (id) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestDeviceAdminApi(id)
        dispatch(deviceAdminLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

