import { requestAddUserAdminApi, requestChangeNoteUserAdminApi, requestChangeStatusUserAdminApi, requestDeleteUserAdminApi, requestEditUserAdminApi, requestUsersAdminApi } from 'utils'
import { usersAdminLoadSuccess, error, finish, loading, userAdminAdd } from './usersSlice'
import { notification } from 'antd'

export const requestUsersAdmin = (inStaff, roleId, date_to, date_from) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestUsersAdminApi(inStaff, roleId, date_to, date_from)
        dispatch(usersAdminLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requestAddUserAdmin = (data, lang) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestAddUserAdminApi(data, lang)
        if(responce.data.data) {
            dispatch(userAdminAdd(responce.data.data))
        }
    } catch (err) {
        dispatch(error({ error: err }))
        notification.error({
            message: 'Ошибка',
            description: err.response?.data?.message,
        })
    } finally {
        dispatch(finish())
    }
}

export const requestEditUserAdmin = (id, data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestEditUserAdminApi(id, data)
        if (responce.data.data) {
            dispatch(requestUsersAdmin())
        }
    } catch (err) {
        dispatch(error({ error: err }))
        notification.error({
            message: 'Ошибка',
            description: err.response?.data?.message,
        })
    } finally {
        dispatch(finish())
    }
}

export const requestChangeStatusUserAdmin = (id, data, inStaff, roleId, date_to, date_from) => async (dispatch) => {
    try {
        await requestChangeStatusUserAdminApi(id, data)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestUsersAdmin(inStaff, roleId, date_to, date_from))
    }
}

export const requestChangeNoteUserAdmin = (id, data) => async (dispatch) => {
    try {
        await requestChangeNoteUserAdminApi(id, data)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
    }
}

export const requestDeleteUserAdmin = (userId) => async (dispatch) => {
    try {
        await requestDeleteUserAdminApi(userId)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
    }
}
