import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useContext } from 'react'

import { ReactComponent as LogoIcon } from 'images/logoIcon.svg'
import { LanguageContext, LanguageProvider } from 'helpers'
import { requestUserInfo } from 'redux/configStore'
import { Header } from 'components/_organisms'
import { routes } from 'router/config'
import './style.scss'
import { Loader } from 'components/_atoms'

export const InnerContext = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { language, changeLanguage } = useContext(LanguageContext)

    const user = useSelector((state) => state.user.user)
    const loading = useSelector((state) => state.user.isLoading)
    const titleHeader = `${user.role?.title}: ${user.firstname} ${user.surname}`
    const [loader, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 4000)
    }, [])

    useEffect(() => {
        dispatch(requestUserInfo(language))
    }, [dispatch, language])

    const returnHome = () => {
        navigate(routes.home)
    }

    return (
        <>
            {loading ? (<Loader />) : (
                <>
                    <div className="wrapper">
                        <Header name={titleHeader} loading={loading} />
                        <Outlet />
                    </div>
                    <div className="footer no-printme">
                        <LogoIcon className="header__logo" onClick={returnHome}/>
                    </div>
                </>
            )}
        </>
    )
}