import { requestUser } from 'utils'
import { userLoadSuccess, loading, error, finish } from './userSlice'

export const requestUserInfo = (language) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestUser(language)
        dispatch(userLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }

}