import { notification } from 'antd'

import { requestAddTransactionsAdminApi, requestDeleteTransactionsAdminApi, requestEditTransactionsAdminApi, requestTransactionsAdminApi } from 'utils'
import { transactionsAdminAdd, error, finish, loading, transactionsAdminLoadSuccess} from './transactionsAdminSlice'

export const requestTransactionsAdmin = (clientId) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestTransactionsAdminApi(clientId)
        dispatch(transactionsAdminLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requestAddTransactionsAdmin = (clientId, data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestAddTransactionsAdminApi(data)
        if(responce.data.data) {
            dispatch(transactionsAdminAdd(responce.data.data))
        }
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
        dispatch(requestTransactionsAdmin(clientId))
    }
}

export const requestEditTransactionsAdmin = (clientId, transactionId, data) => async (dispatch) => {
    try {
        dispatch(loading())
        await requestEditTransactionsAdminApi(transactionId, data)
        dispatch(requestTransactionsAdmin(clientId))
    } catch (err) {
        dispatch(error({ error: err }))
        notification.error({
            message: 'Ошибка',
            description: err.response?.data?.message,
        })
    } finally {
        dispatch(finish())
    }
}

export const requestDeleteTransactionsAdmin = (clientId, transactionId) => async (dispatch) => {
    try {
        await requestDeleteTransactionsAdminApi(transactionId)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestTransactionsAdmin(clientId))
    }
}