import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_DEVICES_ADMIN } from './constant'

const initialState = {
    devicesAdmin: [],
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const devicesSlice = createSlice({
    name: MODULE_NAME_DEVICES_ADMIN,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        devicesAdminLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.devicesAdmin = action.payload
        },
        deviceAdminAdd(state, action) {
            state.isLoaded = true
            state.devicesAdmin = [ ...state.devicesAdmin, action.payload ]
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default devicesSlice.reducer
export const { loading, error, devicesAdminLoadSuccess, finish, deviceAdminAdd } = devicesSlice.actions