import { HTTP_METHODS } from 'helpers'
import { Fetcher } from 'service'

const fetcher = new Fetcher()

export const requestSignIn = (data) => 
    fetcher.requestToReceive({
        url: 'login',
        method: HTTP_METHODS.POST,
        data,
})

export const requestUser = (lang) =>
    fetcher.requestToReceive({
        url: 'user',
        method: HTTP_METHODS.GET,
        params: {lang}
    })