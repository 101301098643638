import { requestAddPlanAdminApi } from 'utils/admin/client/api'
import { requestMarkersAdmin } from '../markersAdmin'
import { planAdminLoadSuccess, error, finish, loading, planAdminAdd } from './floorPlanAdminSlice'
import { requestEditPlanAdminApi, requestFloorPlanAdminApi, requestUpdateFloorPlanAdminCommentApi } from 'utils'

export const requestPLanAdmin = (id) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestFloorPlanAdminApi(id)
        dispatch(planAdminLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requestPLanEditAdmin = (idClient, id, data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestEditPlanAdminApi(idClient, id, data)
        if(responce.data.data) {
            dispatch(planAdminAdd(responce.data.data))
        }
        dispatch(requestPLanAdmin(idClient))
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        requestPLanAdmin(idClient)
    }
}

export const requestPLanAddAdmin = (id, data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestAddPlanAdminApi(id, data)
        if(responce.data.data) {
            dispatch(planAdminAdd(responce.data.data))
        }
        dispatch(requestPLanAdmin(id))
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
    }
}

export const requestPlanCommentEditAdmin = (categoryId, planId, data) => async (dispatch) => {
    try {
        dispatch(loading())
        await requestUpdateFloorPlanAdminCommentApi(categoryId, data)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestMarkersAdmin(planId))
    }
}