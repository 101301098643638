import { requestAddVideoAdminApi, requestDeleteVideoAdminApi } from 'utils'
import { videoAdminAdd, error, finish, loading} from './videoAdminSlice'
import { requestPLanAdmin } from '../floorPlanAdmin'

export const requestAddVideoAdmin = (id, data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestAddVideoAdminApi(id, data)
        if(responce.data.data) {
            dispatch(videoAdminAdd(responce.data.data))
        }
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
        dispatch(requestPLanAdmin(id))
    }
}

export const requestDeleteVideoAdmin = (idClient, id) => async (dispatch) => {
    try {
        await requestDeleteVideoAdminApi(idClient, id)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestPLanAdmin(idClient))
    }
}