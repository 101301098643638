import { error, finish, loading, markerLoadSuccess } from './markerSlice'
import { requestPlanPickerMarkerApi } from 'utils'

export const requestPlanMarkerPicker = (id) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestPlanPickerMarkerApi(id)
        dispatch(markerLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}
