import { useSelector } from 'react-redux'

import { AdminHome, ManagerHome, MeasurerHome, PickerHome } from '../../_templates'
import { Spin } from 'antd'

export const Home = () => {
    const user = useSelector((state) => state.user.user)
    const loading = useSelector((state) => state.user.isLoading)

    const HomeComponent = () => {
        if (user.role?.id === 2){
            return (
                <MeasurerHome />
            )
        }
        if ((user.role?.id === 1)) {
            return (
                <AdminHome />
            )
        }
        if (user.role?.id === 3) {
            return (
                <PickerHome />
            )
        }
        if (user.role?.id === 4) {
            return (
                <ManagerHome />
            )
        }
    }

    return(
        <>
            {loading ? <Spin className="spin-list"/> : <HomeComponent />}
        </>
    )
}