import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_IMAGE_MEASURER } from './constant'

const initialState = {
    image: {},
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const imageSlice = createSlice({
    name: MODULE_NAME_IMAGE_MEASURER,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        imageLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.image = action.payload
        },
        imageAdd(state, action) {
            state.isLoaded = true
            state.image = { ...state.image, ...action.payload }
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default imageSlice.reducer
export const { loading, error, imageLoadSuccess, finish, imageAdd } = imageSlice.actions