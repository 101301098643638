import { Input, Spin, Typography } from 'antd'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useDispatch, useSelector } from 'react-redux'
import ReactInputMask from 'react-input-mask'
import { useContext } from 'react'

import { LanguageContext } from 'helpers'
import './style.scss'
import { useState } from 'react'
import { requestEditClientAdmin } from 'redux/configStore/admin/clients/action'
import { useParams } from 'react-router-dom'
import { requestEditClientMeasurer } from 'redux/configStore'
import { useEffect } from 'react'

export const Table = ({ loading, dataClient, initials, address, clientType, square, phone, email, disable}) => {
    const params = useParams()
    const dispatch = useDispatch()

    const clientId = params.id

    const [phoneEdit, setPhoneEdit] = useState('')
    const [emailEdit, setEmailEdit] = useState('')
    const { language } = useContext(LanguageContext)
    const user = useSelector((state) => state.user.user)

    const handlePhoneChange = (value, country) => {
        setPhoneEdit(value)
        if (user.id === 2) {
            const data = {
                info_type: dataClient.type,
                name: dataClient.name,
                address: dataClient.address,
                area: dataClient.info?.area,
                phone: phoneEdit,
                email: dataClient.info?.email,
                note: dataClient.note
            }
            const dataLegal = {
                info_type: dataClient.type,
                address: dataClient.address,
                area: dataClient.info?.contact?.area,
                phone: phoneEdit,
                email: dataClient.info?.contact?.email,
                note: dataClient.note
            }
            if (clientId !== null) {
                (dataClient.type === "App\\Models\\Company") ?
                    dispatch(requestEditClientMeasurer(clientId, dataLegal, language))
                    : dispatch(requestEditClientMeasurer(clientId, data, language))
            }
        } else {
            const data = {
                info_type: dataClient.type,
                name: dataClient.name,
                address: dataClient.address,
                area: dataClient.info?.area,
                phone: phoneEdit,
                email: dataClient.info?.email,
                note: dataClient.note
            }
            const dataLegal = {
                info_type: dataClient.type,
                address: dataClient.address,
                area: dataClient.info?.contact?.area,
                phone: phoneEdit,
                email: dataClient.info?.contact?.email,
                note: dataClient.note
            }
            if (clientId !== null) {
                (dataClient.type === "App\\Models\\Company") ?
                    dispatch(requestEditClientAdmin(clientId, dataLegal, language))
                    : dispatch(requestEditClientAdmin(clientId, data, language))
            }
        }
    }

    const onChangeArea = (e) => {
        const inputValue = e.target.value
        if (user.id === 2) {
            const data = {
                info_type: dataClient.type,
                name: dataClient.name,
                address: dataClient.address,
                area: inputValue,
                phone: dataClient.info?.phone,
                email: dataClient.info?.email,
                note: dataClient.note
            }
            const dataLegal = {
                info_type: dataClient.type,
                address: dataClient.address,
                area: inputValue,
                phone: dataClient.info?.contact?.phone,
                email: dataClient.info?.contact?.email,
                note: dataClient.note
            }
            if (clientId !== null) {
                (dataClient.type === "App\\Models\\Company") ?
                    dispatch(requestEditClientMeasurer(clientId, dataLegal, language))
                    : dispatch(requestEditClientMeasurer(clientId, data, language))
            }
        } else {
            const data = {
                info_type: dataClient.type,
                name: dataClient.name,
                address: dataClient.address,
                area: inputValue,
                phone: dataClient.info?.phone,
                email: dataClient.info?.email,
                note: dataClient.note
            }
            const dataLegal = {
                info_type: dataClient.type,
                address: dataClient.address,
                area: inputValue,
                phone: dataClient.info?.contact?.phone,
                email: dataClient.info?.contact?.email,
                note: dataClient.note
            }
            if (clientId !== null) {
                (dataClient.type === "App\\Models\\Company") ?
                    dispatch(requestEditClientAdmin(clientId, dataLegal, language))
                    : dispatch(requestEditClientAdmin(clientId, data, language))
            }
        }
    }

    const onChangeEmail = (e) => {
        const enteredEmail = e.target.value;
        setEmailEdit(enteredEmail);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(enteredEmail);

        if (isValidEmail === true) {
            if (user.id === 2) {
                const data = {
                    info_type: dataClient.type,
                    name: dataClient.name,
                    address: dataClient.address,
                    area: dataClient.info?.area,
                    phone: dataClient.info?.phone,
                    email: enteredEmail,
                    note: dataClient.note
                }
                const dataLegal = {
                    info_type: dataClient.type,
                    address: dataClient.address,
                    area: dataClient.info?.contact?.area,
                    phone: dataClient.info?.contact?.phone,
                    email: enteredEmail,
                    note: dataClient.note
                }
                if (clientId !== null) {
                    (dataClient.type === "App\\Models\\Company") ?
                        dispatch(requestEditClientMeasurer(clientId, dataLegal, language))
                        : dispatch(requestEditClientMeasurer(clientId, data, language))
                }
            } else {
                const data = {
                    info_type: dataClient.type,
                    name: dataClient.name,
                    address: dataClient.address,
                    area: dataClient.info?.area,
                    phone: dataClient.info?.phone,
                    email: enteredEmail,
                    note: dataClient.note
                }
                const dataLegal = {
                    info_type: dataClient.type,
                    address: dataClient.address,
                    area: dataClient.info?.contact?.area,
                    phone: dataClient.info?.contact?.phone,
                    email: enteredEmail,
                    note: dataClient.note
                }
                if (clientId !== null) {
                    (dataClient.type === "App\\Models\\Company") ?
                        dispatch(requestEditClientAdmin(clientId, dataLegal, language))
                        : dispatch(requestEditClientAdmin(clientId, data, language))
                }
            }
        }
    }

    useEffect(() => {
        setPhoneEdit('');
        setEmailEdit('')
    }, [clientId]);

    return (
        <div className="table no-printme">
            <div className="table__left">
                <div className="table__row-first">
                    <Typography className="table__title">{language === 'ru' ? `ФИО`: `FCs`}</Typography>
                    {loading ? <Spin className="spin"/> :
                        <Typography className="table__desc">{initials}</Typography>
                    }
                </div>
                <div className="table__row-second">
                    <Typography className="table__title">{language === 'ru' ? `Адрес`: `Address`}</Typography>
                    {loading ? <Spin className="spin"/> :
                        <Typography className="table__desc__address">{address}</Typography>
                    }
                </div>
                <div className="table__row-end">
                    <Typography className="table__title">{language === 'ru' ? `Тип клиента`: `Client type`}</Typography>
                    {loading ? <Spin className="spin"/> :
                        <Typography className="table__desc">{clientType}</Typography>
                    }
                </div>
            </div>
            <div className="table__right">
                <div className="table__row-first">
                    <Typography className="table__title">{language === 'ru' ? `Площадь помещения`: `Room area`}</Typography>
                    {loading ? <Spin className="spin"/> :
                        <>
                            {user.id === '3' ?
                                <Typography className="table__desc">{square}</Typography>:
                                <Input
                                    className="phone-table__input"
                                    defaultValue={square}
                                    onChange={(e) => onChangeArea(e)}
                                    disabled={disable}
                                />
                            }
                        </>
                    }
                </div>
                <div className="table__row-second">
                    <Typography className="table__title">{language === 'ru' ? `Номер телефона`: `Phone number`}</Typography>
                    {loading ? <Spin className="spin"/> :
                        <>
                            {user.id === '3' ?
                                <Typography className="table__desc">{phone}</Typography>:
                                <PhoneInput
                                    className="phone-table"
                                    country={'ge'}
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    inputComponent={Input}
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        disabled: disable,
                                    }}
                                />
                            }
                        </>
                    }
                </div>
                <div className="table__row-end">
                    <Typography className="table__title">Email</Typography>
                    {loading ? <Spin className="spin"/> :
                        <>
                            {user.id === '3' ?
                                <Typography className="table__desc">{email}</Typography>:
                                <div className="phone-table">
                                    <Input
                                        placeholder='Введите email'
                                        className="phone-table__input"
                                        as={ReactInputMask}
                                        mask="*{1,}@*{1,}.*{3,}"
                                        maskChar=""
                                        onChange={(e) => onChangeEmail(e)}
                                        defaultValue={email}
                                        disabled={disable}
                                    />
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}