import { HTTP_METHODS } from 'helpers'
import { Fetcher } from 'service'

const fetcher = new Fetcher()

export const requestPlanPickerApi = (id) =>
    fetcher.requestToReceive({
        url: `picker/clients/${id}/floor-plan`,
        method: HTTP_METHODS.GET
    })

export const requestPlanPickerMarkerApi = (id) =>
    fetcher.requestToReceive({
        url: `picker/floor-plans/${id}/markers`,
        method: HTTP_METHODS.GET
    })

export const requestDevicesClientPickerApi = (id) =>
    fetcher.requestToReceive({
        url: `picker/devices?device_category_id=${id}`,
        method: HTTP_METHODS.GET
    })