import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_USER_ADMIN } from './constant'

const initialState = {
    userAdmin: {},
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const userSlice = createSlice({
    name: MODULE_NAME_USER_ADMIN,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        userAdminLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.userAdmin = action.payload
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default userSlice.reducer
export const { loading, error, userAdminLoadSuccess, userAdminAdd, finish } = userSlice.actions