import { Collapse, Typography } from 'antd'
import { useContext } from 'react'

import { ReactComponent as ArrowUp } from 'images/arrowUp.svg'
import { LanguageContext, getDate } from 'helpers'
import './style.scss'

const { Panel } = Collapse

export const Statistics = ({ data }) => {

    const { language } = useContext(LanguageContext)

    const genExtra = () => (
        <ArrowUp
            onClick={(event) => {
                event.stopPropagation()
            }}
        />
    )

    return (
        <Collapse
            defaultActiveKey={['1']}
            className="statistic no-printme"
        >
            <Panel header={language === 'ru' ? `Статистика по изменениям в проекте`: `Statistics on changes in the project`} key="1" extra={genExtra()}>
                {data.map((item) => 
                    <Typography className="statistic__title" key={item.id}>
                        {`${item.text} (${item.role.title} - ${item.user.firstname} ${item.user.surname}) `}
                        <span>{getDate(item.datetime)}</span>
                    </Typography>
                )}
            </Panel>
        </Collapse>
    )
}