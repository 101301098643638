import { Button, Select, Spin, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'

import { ReactComponent as LogoutIcon } from 'images/logoutIcon.svg'
import { ReactComponent as LogoIcon } from 'images/logoIcon.svg'
import { LanguageContext } from 'helpers'
import { routes } from 'router'
import './style.scss'

export const Header = ({ name, loading }) => {
    const navigate = useNavigate()

    const { language, changeLanguage } = useContext(LanguageContext)

    // const handleChange = (selectedOption) => {
    //     changeLanguage(selectedOption)
    //     if(selectedOption === 'ru') {
    //         return localStorage.setItem('lang', 'ru')
    //     } else {
    //         localStorage.setItem('lang', 'en')
    //     }
    // }

    const returnHome = () => {
        navigate(routes.home)
    }

    const logout = () => {
        localStorage.clear()
        navigate(routes.login)
    }

    return (
        <div className="header">
            <LogoIcon className="header__logo" onClick={returnHome}/>
            <div className="header__content no-printme">
                {loading ? <Spin className="spin"/> :
                    <Typography className="header__title">{name}</Typography>
                }
                {/* <Select
                    defaultValue={language}
                    className="header__select"
                    onChange={(selectedOption) =>
                        handleChange(selectedOption)
                    }
                    options={[
                        { value: 'en', label: 'en' },
                        { value: 'ru', label: 'ru'},
                    ]}
                /> */}
                <Button className="header__btn" onClick={logout}>
                    <Typography className="header__btn_title">{language === 'ru' ? `Выход`: `Log out`}</Typography>
                    <LogoutIcon className="header__btn_icon" />
                </Button>
            </div>
        </div>
    )
}