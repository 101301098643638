import { requestEsimateAdminApi } from 'utils'
import { estimateAdminLoadSuccess, error, finish } from './estimateAdminSlice'

export const requestEstimateAdmin = (id, lang) => async (dispatch) => {
    try {
        const responce = await requestEsimateAdminApi(id, lang)
        if(responce.data.data) {
            dispatch(estimateAdminLoadSuccess(responce.data.data))
        }
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}
