import { requestDeleteDeviceCategoryMeasurerApi } from 'utils/measurer/deviceCategory/api'
import { deviceGategoryLoadSuccess, error, finish, loading } from './deviceGategorySlice'
import { requestDeviceCategoryMeasurer } from 'utils'
import { requestMarkersMeasurer } from '../markers'

export const requestDevicesMeasurer = (id) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestDeviceCategoryMeasurer()
        dispatch(deviceGategoryLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requestDeleteDeviceCategoryMeasurer = (id, planId) => async (dispatch) => {
    try {
        await requestDeleteDeviceCategoryMeasurerApi(id)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestMarkersMeasurer(planId))
    }
}