import { HTTP_METHODS } from 'helpers'
import { Fetcher } from 'service'

const fetcher = new Fetcher()

export const requestDeviceCategoryMeasurer = () =>
    fetcher.requestToReceive({
        url: 'measurer/device-categories',
        method: HTTP_METHODS.GET
    })

export const requestDeleteDeviceCategoryMeasurerApi = (idDeviceCategoryMarker) =>
    fetcher.requestToReceive({
        url: `measurer/device-category-markers/${idDeviceCategoryMarker}`,
        method: HTTP_METHODS.DELETE
    })