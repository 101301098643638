import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_DEVICES_CATEGORY_ADMIN } from './constant'

const initialState = {
    devicesCategoryAdmin: [],
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const devicesCategorySlice = createSlice({
    name: MODULE_NAME_DEVICES_CATEGORY_ADMIN,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        devicesCategoryAdminLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.devicesCategoryAdmin = action.payload
        },
        addDeviceCategoryAdmin(state, action) {
            state.isLoaded = true
            state.devicesCategoryAdmin = [...state.devicesCategoryAdmin, ...action.payload]
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default devicesCategorySlice.reducer
export const { loading, error, devicesCategoryAdminLoadSuccess, addDeviceCategoryAdmin, finish } = devicesCategorySlice.actions