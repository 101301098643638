import { addmanufacturerAdmin, error, finish, loading, manufacturerAdminLoadSuccess } from './manufacturerSlice'
import { requesAddManufacturerAdminApi, requesEditManufacturerAdminApi, requestDeleteManufacturerAdminApi, requestManufacturerAdminApi } from 'utils'

export const requestManufacturerAdmin = (lang) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestManufacturerAdminApi(lang)
        dispatch(manufacturerAdminLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requesAddManufacturerAdmin = (data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requesAddManufacturerAdminApi(data)
        if(responce.data.data) {
            dispatch(addmanufacturerAdmin(responce.data.data))
        }
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
        dispatch(requestManufacturerAdmin())
    }
}

export const requesEditManufacturerAdmin = (id, data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requesEditManufacturerAdminApi(id, data)
        if (responce.data.data) {
            dispatch(requestManufacturerAdmin())
        }
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
    }
}

export const requestDeleteManufacturerAdmin = (id) => async (dispatch) => {
    try {
        await requestDeleteManufacturerAdminApi(id)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestManufacturerAdmin())
    }
}