import { Button, Empty, Input, Radio, Spin, Typography } from 'antd'
import { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { requestAddDevicePicker, requestDeleteDeviceSelectionPicker, requestDevicesPicker,
    requestEditCountDevicePicker } from 'redux/configStore/picker/devices/action'
import { ReactComponent as IconMinusDisable } from 'images/minusDisableIcon.svg'
import { ReactComponent as IconPlusDisable } from 'images/addDisableIcon.svg'
import { ReactComponent as IconDelete } from 'images/deleteIcon.svg'
import { ReactComponent as IconPlus } from 'images/plusIcon.svg'
import { ReactComponent as IconMinus } from 'images/minusIcon.svg'
import { Modal } from '../Modal'
import './style.scss'
import { LanguageContext } from 'helpers'

export const CardEquipment = ({ count, marker, planId, disable }) => {
    const parms = useParams()
    const dispatch = useDispatch()
    const { language } = useContext(LanguageContext)

    const idClient = parms.id

    const dataDevicesClient = useSelector((state) => state.clientDevicesPicker.clientDevicesPicker)
    const dataDevicesClientLoading = useSelector((state) => state.clientDevicesPicker.isLoading)
    const deviceSelectionId = useSelector((state) => state.clientDevicesPicker.deviceSelectionId)
    const [deviceCateogoryId, setDeviceCateogoryId] = useState(null)
    const [deviceCategoryTitle, setDeviceCategoryTitle] = useState('')
    const [markerCategoryId, setMarkerCategoryId] = useState(null)
    const [newMarkerData, setNewMarkerData] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [deviceId, setDeviceId] = useState()

    useEffect(() => {
        if (deviceCateogoryId !== null) {
            dispatch(requestDevicesPicker(deviceCateogoryId, language))
        }
    }, [deviceCateogoryId, language])

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const handleSelectCard = (deviceMarkerId, deviceCateogoryId, deviceCategoryTitle) => {
        setDeviceCateogoryId(deviceCateogoryId)
        setDeviceCategoryTitle(deviceCategoryTitle)
        setMarkerCategoryId(deviceMarkerId)
        showModal()
    }

    const onChangeDeviceId = (idDevice) => {
        setDeviceId(idDevice)
    }

    const onChangeCount = (e, device_category_id, device_selection_id) => {
        if (deviceSelectionId === device_selection_id) {
            return
        }
        const data = {
            count: +e.target.value || 0,
        }
        dispatch(requestEditCountDevicePicker(marker.id, device_category_id, device_selection_id, data, idClient))
    }

    const handleDeviceSelection = () => {
        const data = {
            device_id: deviceId,
            device_category_marker_id: markerCategoryId,
            count: 1,
        }
        setNewMarkerData(data)
        // if (newMarkerData) {
        //     dispatch(requestAddDevicePicker(newMarkerData, planId, idClient))
        //     setIsModalOpen(false)
        // }
    }

    useEffect(() => {
        if (newMarkerData) {
            dispatch(requestAddDevicePicker(newMarkerData, planId, idClient));
            setIsModalOpen(false);
        }
    }, [newMarkerData, dispatch, planId, idClient, setIsModalOpen]);

    const handleDeleteDevice = (selectionId) => {
        dispatch(requestDeleteDeviceSelectionPicker(selectionId, planId, idClient))
    }

    return (
        <div className="card-equipment">
            <div className="card-equipment__header">
                <div className="card-equipment__count">
                    <Typography className="card-equipment__count__title">№{count+1}</Typography>
                </div>
            </div>
            {marker.device_categories?.map((item, index) =>
                <>
                    <div className="card-equipment__list" key={item.id}>
                        {!item.device_selection ?
                            <>
                                <div className="card-equipment__item">
                                    <Typography className="card-equipment__title">{item.device_category?.title}</Typography>
                                    <Button className="card-equipment__btn" onClick={() => handleSelectCard(item.id, item.device_category?.id, item.device_category?.title)} disabled={disable}>
                                        <Typography className="card-equipment__btn__title">{language === 'ru'? `выбрать устройство`: `select a device`}</Typography>
                                    </Button>
                                </div>
                            </>:
                            <div>
                                <div className="card-equipment__item">
                                    <Typography className="card-equipment__title">{item.device_category?.title}</Typography>
                                    {!disable && <IconDelete className="card-equipment__delete" onClick={() => handleDeleteDevice(item.device_selection?.id)}/>}
                                </div>
                                <Typography className="card-equipment__model">{item.device_selection?.device.title}</Typography>
                                <div className="col">
                                    <Typography className="col_title">{language === 'ru' ? `Количество`: `Quantity`}</Typography>
                                    <div className="count">
                                        <Input
                                            className="count__col"
                                            value={item.device_selection?.count}
                                            onChange={(e) => onChangeCount(e, item.id, item.device_selection?.id)}
                                            disabled={disable}
                                            type="number"
                                            min="0"
                                            step="1"
                                            addonBefore={
                                                <>
                                                    {disable || deviceSelectionId === item.device_selection?.id ? <IconMinusDisable className="count__icon-minus-disable"/> :
                                                        <IconMinus
                                                            onClick={() => onChangeCount({ target: { value: (item.device_selection?.count) - 1 } }, item.id, item.device_selection?.id)}
                                                            className="count__icon-minus"
                                                        />
                                                    }
                                                </>
                                              }
                                              addonAfter={
                                                <>
                                                    {disable || deviceSelectionId === item.device_selection?.id ? <IconPlusDisable className="count__icon-plus-disable"/> :
                                                        <IconPlus
                                                            onClick={() => onChangeCount({ target: { value: (item.device_selection?.count) + 1 } }, item.id, item.device_selection?.id)}
                                                            className="count__icon-plus"
                                                        />
                                                    }
                                                </>
                                              }
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </>
            )}
            {deviceCateogoryId && (
            <Modal
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                component={(
                    <>
                        {dataDevicesClientLoading ? <Spin className="spin"/>:
                            <>
                                <Typography className="picker-device-select__title">{language === 'ru' ? `Каталог`: `Catalog`}</Typography>
                                <Typography className="picker-device-select__desc">{deviceCategoryTitle}</Typography>
                                    {!!dataDevicesClient.length?
                                        <>
                                            <Radio.Group className="picker-device-select__radio" onChange={({ target: { value }}) => onChangeDeviceId(value)}>
                                                {dataDevicesClient.map(device =>
                                                    <>
                                                        <Radio value={device.id}>
                                                            <Typography className="picker-device-select__radio__title">{device.title}</Typography>
                                                        </Radio>
                                                        {!!device.count ?
                                                            <Typography className={"picker-device-select__radio__desc"}>{language === 'ru' ? `На складе`: `In stock`} <span>{`(${device.count})`}</span></Typography>:
                                                            <Typography className={"picker-device-select__radio__desc-red"}>{language === 'ru' ? `Нет в наличии`: `Out of stock`} <span>{`(${device.count})`}</span></Typography>
                                                        }
                                                    </>
                                                )}
                                            </Radio.Group>
                                            <Button className="picker-device-select__radio__btn" onClick={handleDeviceSelection}>
                                                <Typography className="picker-device-select__radio__btn_title">
                                                    {language === 'ru' ? `Выбрать`: `Choose`}
                                                </Typography>
                                            </Button>
                                        </>:
                                        <Empty description={language === 'ru' ? `Нет данных для отображения`: `No data to display`} />
                                    }
                            </>
                        }
                    </>
                )}
            />
            )}
        </div>
    )
}
