import { createSlice } from '@reduxjs/toolkit'

import { MODULE_NAME_MARKERS_ADMIN } from './constant'

const initialState = {
    markersAdmin: [],
    isLoading: false,
    isLoaded: false,
    error: null,
}

export const markersAdminSlice = createSlice({
    name: MODULE_NAME_MARKERS_ADMIN,
    initialState,
    reducers: {
        loading(state) {
            state.isLoading = true
            state.error = null
        },
        error(state, action) {
            const { error } = action.payload
            state.error = error
        },
        markersAdminLoadSuccess(state, action) {
            state.isLoading = false
            state.error = ''
            state.markersAdmin = action.payload
        },
        markerDeviceSelectionEditSuccessAdmin(state, action) {
            const { markerId, deviceCategoryId, deviceSelection } = action.payload
            state.markersAdmin = state.markersAdmin.map(marker => {
                if (marker.id === markerId) {
                    return {
                        ...marker,
                        device_categories: marker.device_categories.map(deviceCategory => {
                            if (deviceCategory.id === deviceCategoryId) {
                                return {
                                    ...deviceCategory,
                                    device_selection: deviceSelection
                                }
                            }
                            return deviceCategory
                        })
                    }
                }
                return marker
            })
        },
        finish(state) {
            state.isLoading = false
        }
    }
})

export default markersAdminSlice.reducer
export const { loading, error, markersAdminLoadSuccess, markerDeviceSelectionEditSuccessAdmin, finish } = markersAdminSlice.actions