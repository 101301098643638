import { HTTP_METHODS } from 'helpers'
import { Fetcher } from 'service'

const fetcher = new Fetcher()

export const requestDevicesPickerApi = (categoryId) =>
    fetcher.requestToReceive({
        url: `picker/devices?device_category_id=${categoryId}`,
        method: HTTP_METHODS.GET
    })
    
export const requestDevicePickerApi = (id) =>
    fetcher.requestToReceive({
        url: `picker/device-selections/${id}`,
        method: HTTP_METHODS.GET
})

export const requestAddDevicePickerApi = (data) =>
    fetcher.requestToReceive({
        url: 'picker/device-selections',
        method: HTTP_METHODS.POST,
        data
    })

export const requestEditDevicePickerApi = (id, data) =>
    fetcher.requestToReceive({
        url: `picker/device-selections/${id}`,
        method: HTTP_METHODS.PUT,
        data
    })

export const requestEditCountDevicePickerApi = (id, data) =>
    fetcher.requestToReceive({
        url: `picker/device-selections/${id}/count`,
        method: HTTP_METHODS.PATCH,
        data,
    })

export const requestDeleteDeviceSelectionPickerApi = (idDeviceSelection) =>
    fetcher.requestToReceive({
        url: `picker/device-selections/${idDeviceSelection}`,
        method: HTTP_METHODS.DELETE
    })