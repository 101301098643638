import { requestAddMarkerMeasurerApi, requestDeleteMarkerMeasurerApi } from 'utils'
import { markerAdd, error, finish, loading } from './markerSlice'
import { requestMarkersMeasurer, requestPLanMeasurer } from '../../measurer'

export const requestAddMarkerMeasurer = (clientId, planId, data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestAddMarkerMeasurerApi(planId, data)
        if(responce.data.data) {
            dispatch(markerAdd(responce.data.data))
        }
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
        dispatch(requestPLanMeasurer(clientId))
        dispatch(requestMarkersMeasurer(planId))
    }
}

export const requestDeleteMarkerMeasurer = (clientId, planId, markerId) => async (dispatch) => {
    try {
        await requestDeleteMarkerMeasurerApi(planId, markerId)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestMarkersMeasurer(planId))
    }
}
