import { Form, DatePicker, Button, Typography, Input, Radio, Spin, notification } from 'antd'
import { useState, useEffect, useLayoutEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import dayjs from 'dayjs'

import { requestEditUserAdmin, requestRolesAdmin, requestUserAdmin } from 'redux/configStore'
import { rolesAdminLoadSuccess } from 'redux/configStore/admin/roles/rolesSlice'
import { requestDeleteUserAdmin } from 'redux/configStore/admin/users/action'
import { userAdminLoadSuccess } from 'redux/configStore/admin/user/userSlice'
import { ADMIN_USER_FORM_REQUIRED_FIELDS } from 'constants/data/data'
import { ReactComponent as IconDelete } from 'images/deleteIcon.svg'
import { ReactComponent as DateIcon } from 'images/dateIcon.svg'
import { ModalSuccess } from 'components/_molecules'
import { BtnBack, Modal } from 'components/_atoms'
import { LanguageContext } from 'helpers'
import { routes } from 'router'
import './style.scss'
import TextArea from 'antd/es/input/TextArea'

export const AdminEditEmployee = () => {
    const parms = useParams()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const id = parms.id

    const { language } = useContext(LanguageContext)

    const roles = useSelector((state) => state.rolesAdmin.rolesAdmin)
    const rolesLoading = useSelector((state) => state.rolesAdmin.isLoading)
    const user = useSelector((state) => state.userAdmin.userAdmin)
    const userLoading = useSelector((state) => state.userAdmin.isLoading)
    const isUserLoading = useSelector((state) => state.usersAdmin.isLoading)
    const userError = useSelector((state) => state.usersAdmin.error)

    const [phone, setPhone] = useState('')
    const [role, setRole] = useState(null)
    const [birthdate, setBirthdate] = useState('')
    const [dateHired, setDateHired] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalOpenDelete, setIsModalOpenDelete] = useState(false)

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
        navigate(routes.employee)
    }

    const onChangeRole = (e) => {
        setRole(e.target.value)
    }

    const onChangeBirthdate = (date, dateString) => {
        setBirthdate(dayjs(dateString, 'YYYY-MM-DD'))
    }

    const onChangeDateHired = (date, dateString) => {
        setDateHired(dayjs(dateString, 'YYYY-MM-DD'))
    }

    const handlePhoneChange = (value, country) => {
        setPhone(value)
    }

    const onFinish = (values) => {
        const data = {
            login: values.login,
            password: values.password,
            firstname: values.firstname,
            surname: values.surname,
            role_id: role,
            citizenship: values.citizenship,
            address: values.address,
            birthdate: birthdate.format('YYYY-MM-DD'),
            phone: values.phone,
            email: values.email,
            when_hired: dateHired.format('YYYY-MM-DD'),
            note: values.note,
            in_staff: true
        }
        dispatch(requestEditUserAdmin(id, data))
        showModal()
    }

    useEffect(() => {
        dispatch(requestRolesAdmin())
        if(id !== null) {
            dispatch(requestUserAdmin(id))
        }
    }, [dispatch, id])

    const showModalDelete = () => {
        setIsModalOpenDelete(true)
    }

    const handleOkDelete = () => {
        setIsModalOpenDelete(false)
        navigate(routes.employee)
    }

    const handleCancelDelete = () => {
        setIsModalOpenDelete(false)
    }

    const deleteEmployee = () => {
        if (id !== undefined) {
            dispatch(requestDeleteUserAdmin(id))
        }
        notification.success({
            message: 'Сотрудник удален',
            description: `удалён ${user.firstname} ${user.surname}`,
        })
        navigate(routes.employee)
    }

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    const onValuesChange = (changedValues, allValues) => {
        const isSubmitDisabled = !ADMIN_USER_FORM_REQUIRED_FIELDS.every(name => !!form.getFieldValue(name))
            || !!form.getFieldsError().filter(({ errors }) => errors.length).length
        setIsSubmitDisabled(isSubmitDisabled)
    }

    useEffect(() => () => {
        dispatch(userAdminLoadSuccess({}))
        dispatch(rolesAdminLoadSuccess([]))
    }, [])

    useLayoutEffect(() => {
        setRole(user.role?.id)
        setBirthdate(dayjs(user.birthdate, 'YYYY-MM-DD'))
        setDateHired(dayjs(user.when_hired, 'YYYY-MM-DD'))
        form.setFieldsValue({
            role: user.role?.id,
            firstname: user.firstname,
            surname: user.surname,
            login: user.login,
            password: user.password,
            birthdate: dayjs(user.birthdate, 'YYYY-MM-DD'),
            citizenship: user.citizenship,
            address: user.address,
            phone: user.phone,
            email: user.email,
            when_hired: dayjs(user.when_hired, 'YYYY-MM-DD'),
            note: user.note
         })
    }, [form, userLoading, rolesLoading, user])

    return (
        <>
            <div className="wrapper-admin">
                <BtnBack route={routes.employee} />
                <Typography className="add-device_title">
                    {language === 'ru' ? `Сотрудник`: `Employee`}
                </Typography>
                <IconDelete className="add-device-delete cursor" onClick={showModalDelete}/>
            </div>
            {userLoading ? <Spin className="spin-list"/> :
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                    className="add-emplyee__form"
                    onValuesChange={onValuesChange}
                >
                    <Form.Item
                        name="role"
                        label={language === 'ru' ? `Тип сотрудника`: `Employee type`}
                        key={`role_${user.id}`}
                    >
                        <div className="add-emplyee__form__wrapper">
                            {rolesLoading ? <Spin className="spin"/> :
                                <Radio.Group className="add-emplyee__form__wrapper__radio-group" onChange={onChangeRole} value={role}>
                                    {roles.map((item, index) =>
                                        <Radio value={item.id} key={`role${index}`}>{item.title}</Radio>
                                    )}
                                </Radio.Group>
                            }
                        </div>
                    </Form.Item>
                    <Form.Item
                        name="firstname"
                        label={language === 'ru' ? `Имя *`: `Name *`}
                        key={`firstname_${user.id}`}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="surname"
                        label={language === 'ru' ? `Фамилия *`: `Surname *`}
                        key={`surname_${user.id}`}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="login"
                        label={language === 'ru' ? `Логин *`: `Login *`}
                        key={`login_${user.id}`}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="birthdate"
                        label={language === 'ru' ? `Дата рождения *`: `Date of birth *`}
                        key={`birthdate_${user.id}`}
                    >
                        <DatePicker
                            placeholder=''
                            suffixIcon={<DateIcon />}
                            onChange={onChangeBirthdate}
                            className="add-emplyee__form__date"
                        />
                    </Form.Item>
                    <Form.Item
                        name="citizenship"
                        label={language === 'ru' ? `Гражданство *`: `Citizenship *`}
                        key={`citizenship_${user.id}`}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="address"
                        label={language === 'ru' ? `Адрес *`: `Address *`}
                        key={`address_${user.id}`}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label={language === 'ru' ? `Номер телефона *`: `Phone number *`}
                        key={`phone_${user.id}`}
                    >
                        <PhoneInput
                            country={'ge'}
                            value={phone}
                            onChange={handlePhoneChange}
                            inputComponent={Input}
                            inputProps={{
                                name: 'phone',
                                required: true,
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email *"
                        key={`email_${user.id}`}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="when_hired"
                        label={language === 'ru' ? `Дата принятия на работу *`: `Date of employment *`}
                        key={`when_hired_${user.id}`}
                    >
                        <DatePicker
                            placeholder=''
                            suffixIcon={<DateIcon />}
                            onChange={onChangeDateHired}
                            className="add-emplyee__form__date"
                        />
                    </Form.Item>
                    <Form.Item
                        name="note"
                        label={language === 'ru' ? `Заметка`: `Note`}
                        key={`note_${user.id}`}
                    >
                        <TextArea className="add-emplyee__form__note" placeholder={language === 'ru' ? `Введите текст`: `Enter the text`}/>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        <Button
                            className="client-add__form__btn"
                            type="primary"
                            htmlType="submit"
                            disabled={isSubmitDisabled || isUserLoading}
                        >
                            <Typography className="client-add__form__btn_title">{language === 'ru' ? `Сохранить`: `Save`}</Typography>
                        </Button>
                    </Form.Item>
                </Form>
            }
            {!isUserLoading && !userError &&
                <ModalSuccess
                    isModalOpen={isModalOpen}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    title={language === 'ru' ? `Сотрудник изменен`: `Employee changed`}
                    route={routes.employee}
                />
            }
            <Modal
                isModalOpen={isModalOpenDelete}
                handleOk={handleOkDelete}
                handleCancel={handleCancelDelete}
                component={(
                    <>
                        <Typography className="delete-warning__title">{`Вы уверены что хотите удалить сотрудника: ${user.firstname} ${user.surname}?`}</Typography>
                        <div className="delete-warning_btn-group">
                            <Button className="delete-warning_btn-group__ok" onClick={deleteEmployee}>
                                <Typography className="delete-warning_btn-group__ok__title">Да</Typography>
                            </Button>
                            <Button className="delete-warning_btn-group__cancel" onClick={handleCancelDelete}>
                                <Typography className="delete-warning_btn-group__cancel__title">Нет</Typography>
                            </Button>
                        </div>
                    </>
                )}
            />
        </>
    )
}
