import { List, Checkbox, Empty } from 'antd'

import './style.scss'

export const ListElement = ({ data, dataDeviceCategories, setDataDeviceCategories }) => {

    const onChange = (event) => {
        const id = event.target.value
        const checked = event.target.checked

        if (checked) {
            setDataDeviceCategories([...dataDeviceCategories, id])
        } else {
            setDataDeviceCategories(dataDeviceCategories.filter((selectedId) => selectedId !== id))
        }
    }

    return (
        <List
            className="list-element"
            dataSource={data}
            locale={{
                emptyText: (
                    <Empty description="Нет данных для отображения" />
                  )
            }}
            renderItem={(item) => (
                <List.Item key={item.id}>
                    <Checkbox onChange={onChange} value={item.id}>{item.title}</Checkbox>
                </List.Item>
            )}
        />
    )
}
