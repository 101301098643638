import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useEffect, useContext } from 'react'
import { Spin, Typography } from 'antd'

import { ReactComponent as AddIcon } from 'images/addIcon.svg'
import { requestClientsInfo } from 'redux/configStore'
import { ListClient } from 'components/_molecules'
import { LanguageContext } from 'helpers'
import { routes } from 'router'
import './style.scss'

export const MeasurerHome = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { language } = useContext(LanguageContext)
    const dataClients = useSelector((state) => state.clients.clients)
    const loading = useSelector((state) => state.clients.isLoading)

    const navigateAddClientPage = () => {
        navigate(routes.clientAdd)
    }

    useEffect(() => {
        dispatch(requestClientsInfo(language))
    }, [dispatch, language])

    return (
        <>
            {loading ? <Spin className="spin-list" /> :
                <>
                    <div className="wrapper__content">
                        <Typography className="wrapper__content_title">{language === 'ru' ? `Клиенты`: `Clients`}</Typography>
                        <AddIcon className="measurer-add" onClick={navigateAddClientPage}/>
                    </div>
                    <ListClient link='measurer-layout' linkEdit='client-edit' data={dataClients} />
                </>
            }
        </>
    )
}