import { HTTP_METHODS } from 'helpers'
import { Fetcher } from 'service'

const fetcher = new Fetcher()

export const requestAddImageMeasurer = (id, data) =>
    fetcher.requestToReceive({
        url: `measurer/clients/${id}/images`,
        method: HTTP_METHODS.POST,
        data
    })

export const requestDeleteImageMeasurer = (idClient, id) =>
    fetcher.requestToReceive({
        url: `measurer/clients/${idClient}/images/${id}`,
        method: HTTP_METHODS.DELETE,
    })