import { Form, Radio, Button, Typography, Input, Spin } from 'antd'
import { useState, useEffect, useLayoutEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { clientsTypeAdminLoadSuccess } from 'redux/configStore/admin/clientsType/clientsTypeSlice'
import { clientAdminLoadSuccess } from 'redux/configStore/admin/client/clientAdminSlice'
import { MEASURER_CLIENT_FORM_REQUIRED_FIELDS } from "../../../constants/data/data"
import { requestEditClientAdmin } from 'redux/configStore/admin/clients/action'
import { requestClientAdmin } from 'redux/configStore/admin/client'
import { requestClientsTypeAdmin } from 'redux/configStore'
import { ModalSuccess } from 'components/_molecules'
import { BtnBack } from 'components/_atoms'
import { routes } from 'router'
import { useContext } from 'react'
import { LanguageContext } from 'helpers'
import TextArea from 'antd/es/input/TextArea'

export const AdminEditClient = () => {
    const params = useParams()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const id = params.id
    const { language } = useContext(LanguageContext)

    useEffect(() => {
        if (id !== null) {
            dispatch(requestClientAdmin(id, language))
            dispatch(requestClientsTypeAdmin(language))
        }
    }, [dispatch, id, language])

    const client = useSelector((state) => state.clientAdmin.clientAdmin)
    const clientsType = useSelector((state) => state.clientsTypeAdmin.clientsTypeAdmin)

    const clientsTypeLoading = useSelector((state) => state.clientsTypeAdmin.isLoading)
    const clientAdminLoading = useSelector((state) => state.clientAdmin.isLoading)

    const clientsLoading = useSelector((state) => state.clientsAdmin.isLoading)
    const clientsError = useSelector((state) => state.clientsAdmin.error)

    const [phone, setPhone] = useState('')
    const [type, setType] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handlePhoneChange = (value, country) => {
        setPhone(value)
    }

    const onChangeType = (e) => {
        setType(e.target.value)
    }

    const onFinish = (values) => {
        const data = {
            info_type: type,
            name: values.name,
            address: values.adress,
            area: values.square,
            phone: values.phone,
            email: values.email,
            link: values.link,
            note: values.note
        }
        const dataLegal = {
            info_type: type,
            company_name: values.nameOrg,
            company_address: values.addressZip,
            payment_account: values.pay,
            post_address: values.legalAddr,
            bank_name: values.nameBank,
            bank_bik: values.bic,
            unp: values.unp,
            okpo: values.OCPO,
            charge: values.acting,
            charge_base: values.inFace,
            name: values.name,
            address: values.adress,
            area: values.square,
            phone: values.phone,
            email: values.email,
            link: values.link,
            note: values.note
        }
        if (id !== null) {
            (type === "App\\Models\\Company") ?
                dispatch(requestEditClientAdmin(id, dataLegal))
                : dispatch(requestEditClientAdmin(id, data))
        }
        showModal()
    }

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        form.resetFields()
        setIsModalOpen(false)
        navigate(routes.home)
    }

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)

    const onValuesChange = (changedValues, allValues) => {
        const isSubmitDisabled = !MEASURER_CLIENT_FORM_REQUIRED_FIELDS.every(name => !!form.getFieldValue(name))
            || !!form.getFieldsError().filter(({ errors }) => errors.length).length
        setIsSubmitDisabled(isSubmitDisabled)
    }

    useEffect(() => () => {
        dispatch(clientAdminLoadSuccess({}))
        dispatch(clientsTypeAdminLoadSuccess([]))
    }, [])

    useLayoutEffect(() => {
        setType(client.type)
        form.setFieldsValue({
            name: client.name,
            adress: client.address,
            type: client.type,
            nameOrg: client.info?.company_name,
            legalAddr: client.info?.company_address,
            pay: client.info?.post_address,
            addressZip: client.info?.payment_account,
            nameBank: client.info?.bank_name,
            bic: client.info?.bank_bik,
            unp: client.info?.unp,
            OCPO: client.info?.okpo,
            inFace: client.info?.charge_base,
            acting: client.info?.charge,
            square: client.type === "App\\Models\\Company" ? client.info?.contact?.area : client.info?.area,
            phone: client.type === "App\\Models\\Company" ? client.info?.contact?.phone : client.info?.phone,
            email: client.type === "App\\Models\\Company" ? client.info?.contact?.email : client.info?.email,
            link: client.link,
            note: client.note
        })
    }, [form, clientAdminLoading, clientsTypeLoading, client])

    return (
        <>
            <div className="wrapper-admin">
                <BtnBack route={routes.orders} />
            </div>
            <Typography className="client-add__title title-one">{language === 'ru' ? `Клиент`: `Client`}</Typography>
            <div className="client-add__form">
            {clientAdminLoading && clientsTypeLoading ? <Spin className="spin-list" /> :
                <Form
                    form={form}
                    key={id}
                    layout="vertical"
                    onFinish={onFinish}
                    onValuesChange={onValuesChange}
                    autoComplete="off"
                >
                    <Form.Item
                        name="name"
                        key={`name_${client.id}`}
                        label={language === 'ru' ? `Имя *`: `Name *`}
                        rules={[{ required: true, message: `${language === 'ru' ? `Введите имя`: `Enter a name`}` }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="adress"
                        label={language === 'ru' ? `Адрес *`: `Address *`}
                        rules={[{ required: true, message: `${language === 'ru' ? `Введите адрес`: `Enter the address`}` }]}
                        key={`adress_${client.id}`}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="type"
                        label={language === 'ru' ? `Тип клиента`: `Type client`}
                        key={`type_${client.id}`}
                    >
                        {clientsTypeLoading ? <Spin className="spin"/> :
                            <Radio.Group onChange={onChangeType}>
                                {clientsType.map((item, index) =>
                                    <Radio value={item.type} key={`clientsType_${index}`}>{item.title}</Radio>
                                )}
                            </Radio.Group>
                        }
                    </Form.Item>
                    {type === "App\\Models\\Company" &&
                        <>
                            <Form.Item
                                name="nameOrg"
                                label={language === 'ru' ? `Наименование организации`: `Name of the organization`}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="legalAddr"
                                label={language === 'ru' ? `Юридический адрес`: `Legal address`}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="pay"
                                label={language === 'ru' ? `Расчётный счёт`: `Payment account`}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="addressZip"
                                label={language === 'ru' ? `Почтовый адрес с индексом`: `Postal address with ZIP code`}
                            >
                                <Input placeholder={language === 'ru' ? `г. Батуми ул. Баку 9–11, 6000`: `Batumi Baku str. 9-11, 6000`} />
                            </Form.Item>
                            <Form.Item
                                name="nameBank"
                                label={language === 'ru' ? `Наименование банка`: `Name of the bank`}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="bic"
                                label={language === 'ru' ? `БИК банка`: `BIC bank`}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="unp"
                                label={language === 'ru' ? `УНП`: `UNP`}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="OCPO"
                                label={language === 'ru' ? `ОКПО`: `OCPO`}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="inFace"
                                label={language === 'ru' ? `В лице`: `In person`}
                            >
                                <Input placeholder={language === 'ru' ? `Директора Алиева Т. М.`: `Director T. M. Aliyev .`} />
                            </Form.Item>
                            <Form.Item
                                name="acting"
                                label={language === 'ru' ? `Действующего на основании`: `Acting on the basis of`}
                            >
                                <Input placeholder={language === 'ru' ? `Устава / Договора`: `Charter / Agreement`} />
                            </Form.Item>
                        </>
                    }
                    <Form.Item
                        name="square"
                        label={language === 'ru' ? `Площадь помещения, м2`: `Room area, m2 *`}
                        key={`area_${client.id}`}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label={language === 'ru' ? `Номер телефона`: `Phone number *`}
                        key={`phone_${client.id}`}
                    >
                        <PhoneInput
                            value={phone}
                            onChange={handlePhoneChange}
                            inputComponent={Input}
                            inputProps={{
                                name: 'phone',
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        key={`email_${client.id}`}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="link"
                        key={`link_${client.id}`}
                        label={language === 'ru' ? `Ссылка на Excel`: `Link to Excel *`}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="note"
                        label={language === 'ru' ? `Заметка`: `Note`}
                        key={`note_${client.id}`}
                    >
                        <TextArea className="add-emplyee__form__note" placeholder={language === 'ru' ? `Введите текст`: `Enter the text`}/>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        <Button
                            className="client-add__form__btn"
                            type="primary"
                            htmlType="submit"
                            disabled={isSubmitDisabled || clientsLoading}
                        >
                            <Typography className="client-add__form__btn_title">{language === 'ru' ? `Сохранить`: `Save`}</Typography>
                        </Button>
                    </Form.Item>
                </Form>
            }
            </div>
            {!clientsLoading && !clientsError && (
                <ModalSuccess
                    isModalOpen={isModalOpen}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    title={language === 'ru' ? `Клиент изменен`: `Client changed`}
                    route={routes.orders}
                />
            )}
        </>
    )
}
