import { Typography, Button } from 'antd'
import { useContext } from 'react'

import { Modal } from 'components/_atoms'
import { LanguageContext } from 'helpers'
import './style.scss'

export const ModalConfirm = ({ isModalOpen, handleOk, handleCancel, handleSuccess }) => {
    const { language } = useContext(LanguageContext)

    return (
        <Modal
            isModalOpen={isModalOpen}
            handleOk={handleOk}
            handleCancel={handleCancel}
            component={(
                <div className="modal-content__select">
                    <div className="layout-save__icon"></div>
                    <Typography className="layout-save__title">
                        {language === 'ru' ? `Подтвердите Ваше действие, т.к после отправки на оценку редактирование будет невозможно`:
                            `Confirm your action, because after sending it for evaluation, editing will be impossible`
                        }
                    </Typography>
                    <div className="btn-layout">
                        <Button className="layout-save__btn" onClick={handleSuccess}>
                            <Typography className="layout-save__btn_title">{language === 'ru' ? `Подтвердить`: `Confirm`}</Typography>
                        </Button>
                    </div>
                </div>
            )}
        />
    )
}