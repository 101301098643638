import { Typography, List, Spin, Empty, Image } from 'antd'
import { useParams } from 'react-router-dom'
import { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'

import { requestDeleteImageAdmin, requestDeleteVideoAdmin, requestImageDeleteMeasurer,
    requestVideoDeleteMeasurer } from 'redux/configStore'
import { ReactComponent as CloseIcon } from 'images/closIcon.svg'
import { ReactComponent as PreviewIcon } from 'images/previewIcon.svg'
import { LanguageContext } from 'helpers'
import './style.scss'

export const Gallery = ({ loading, videoArray, imgArray, flag, disable}) => {
    const params = useParams()
    const dispatch = useDispatch()

    const idClient = params.id
    const { language } = useContext(LanguageContext)

    const [photo, setPhoto] = useState(true)
    const [video, setVideo] = useState(false)
    
    const choosePhoto = () => {
        setPhoto(true)
        setVideo(false)
    }

    const chooseVideo = () => {
        setVideo(true)
        setPhoto(false)
    }

    const deleteVideo = (id) => {
        if (flag === 'measurer') {
            dispatch(requestVideoDeleteMeasurer(idClient, id))
        } else {
            dispatch(requestDeleteVideoAdmin(idClient, id))
        }
    }

    const deleteImg = (id) => {
        if (flag === 'measurer') {
            dispatch(requestImageDeleteMeasurer(idClient, id))
        } else {
            dispatch(requestDeleteImageAdmin(idClient, id))
        }
    }

    return (
        <div className="gallery no-printme">
            <div className="gallery_header">
                <div className={photo === true ? "gallery_header__left_active" : "gallery_header__left"} onClick={choosePhoto}>
                    <Typography className={photo === true ? "gallery__title-active" : "gallery__title"}>{language === 'ru'? `Фото`: `Photo`}</Typography>
                </div>
                <div className={video === true ?  "gallery_header__right_active": "gallery_header__right"} onClick={chooseVideo}>
                    <Typography className={video === true ? "gallery__title-active" : "gallery__title"}>{language === 'ru'? `Видео`: `Video`}</Typography>
                </div>
            </div>
            {loading ? <Spin className="spin-list" /> :
                <>
                    {video === true ?
                        <List
                            grid={{
                                column: 2,
                            }}
                            dataSource={videoArray}
                            locale={{
                                emptyText: (
                                    <Empty description={language === 'ru'? `Нет данных для отображения`: `No data to display`} />
                                  )
                            }}
                            renderItem={(item) => (
                                <List.Item key={item.id}>
                                    <div className="group" key={item.id}>
                                        <video key={item.id} controls className="video-item">
                                            <source src={item.path} type="video/mp4" />
                                        </video>
                                        {(flag === 'measurer' || flag === 'admin') && !disable && <CloseIcon className="icon-delete" onClick={(e) => deleteVideo(item.id)}/>}
                                    </div>
                                </List.Item>
                            )}
                        /> : 
                        <List
                            grid={{
                                column: 4,
                            }}
                            dataSource={imgArray}
                            locale={{
                                emptyText: (
                                    <Empty description={language === 'ru'? `Нет данных для отображения`: `No data to display`} />
                                  )
                            }}
                            renderItem={(item) => (
                                <List.Item key={item.id}>
                                    <div className="group" key={item.id}>
                                        {(flag === 'measurer' || flag === 'admin') && !disable &&
                                            <CloseIcon className="icon-delete" onClick={() => deleteImg(item.id)} />}
                                            <div className="gallery__img">
                                                <Image src={item.path} alt="" className="gallery__img" preview={{
                                                     mask: <PreviewIcon className="gallery__img_preview"/>,
                                                }}/>
                                            </div>
                                    </div>
                                </List.Item>
                            )}
                        />
                    }
                </>
            }
        </div>
    )
}