import { Button, Typography } from 'antd'
import * as XLSX from 'xlsx'

import './style.scss'

export const ExportExcelBtn = ({ devices, nameFile }) => {
    const exportToExcel = () => {
        const data = devices.map(device => ({
            "Модель": device.title,
            "Ссылка на устройство": device.link,
            "Код устройства": device.product_code,
            "Функция устройства": device.device_type.title,
            "Тип устройства": device.device_type.device_category.title,
            "Кол-во на складе": device.count,
            "Производитель": device.manufacturer.title,
            "Протоколы": device.protocols.map(protocol => protocol.title).join(", "),
            "Описание": device.description,
            "Заметка": device.note,
            "Продано": device.sold_count
        }));
        const sheet = XLSX.utils.json_to_sheet(data);

        const headerStyle = {
        font: { bold: true, sz: 18 },
        alignment: { wrapText: true }
        };

        const headerRange = XLSX.utils.decode_range(sheet["!ref"]);
        for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
        sheet[cellAddress].s = headerStyle;
        sheet[cellAddress].s.alignment = { vertical: 'top' };
        }

        const defaultStyle = {
        font: { sz: 14 },
        alignment: { wrapText: true }
        };

        const range = XLSX.utils.decode_range(sheet["!ref"]);
        for (let row = range.s.r; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            sheet[cellAddress].s = defaultStyle;
            sheet[cellAddress].s.alignment = { vertical: 'top' };
        }
        }

        const columnWidths = Object.keys(sheet).map(column => ({
        width: calculateColumnWidth(sheet[column]?.v)
        }));

        sheet['!cols'] = columnWidths;

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, sheet, "Data");

        const excelBuffer = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'array',
        cellStyles: true
        });

        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = `${nameFile}.xlsx`;

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
        } else {
        const url = window.URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 100);
        }
    };
    const calculateColumnWidth = (value) => {
        const charactersInPixel = 2;
        const paddingInPixels = 5;
        const minWidthInPixels = 20;
        const maxWidthInPixels = 30;
        if (value == null) {
            return minWidthInPixels;
          }
          
          const textWidthInPixels = value.toString().length * charactersInPixel + paddingInPixels;
          return Math.min(Math.max(minWidthInPixels, textWidthInPixels), maxWidthInPixels);
        };
    
    return (
        <Button className="btn-excel" onClick={exportToExcel}>
            <Typography className="btn-excel__title">Экспортировать в excel</Typography>
        </Button>
    )
}