import { addprotocolAdmin, error, finish, loading, protocolAdminLoadSuccess } from './protocolSlice'
import { requesAddProtocolAdminApi, requesEditProtocolAdminApi, requestDeleteProtocolAdminApi, requestProtocolAdminApi } from 'utils'

export const requestProtocolAdmin = (lang) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestProtocolAdminApi(lang)
        dispatch(protocolAdminLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requesAddProtocolAdmin = (data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requesAddProtocolAdminApi(data)
        if(responce.data.data) {
            dispatch(addprotocolAdmin(responce.data.data))
        }
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
        dispatch(requestProtocolAdmin())
    }
}

export const requesEditProtocolAdmin = (id, data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requesEditProtocolAdminApi(id, data)
        if (responce.data.data) {
            dispatch(requestProtocolAdmin())
        }
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
    }
}

export const requestDeleteProtocolAdmin = (id) => async (dispatch) => {
    try {
        await requestDeleteProtocolAdminApi(id)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestProtocolAdmin())
    }
}