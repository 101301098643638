import { useMemo, useState, useContext } from 'react'
import { Empty, List, Typography } from 'antd'
import TextArea from 'antd/es/input/TextArea'

import { ReactComponent as CloseIcon } from 'images/closeListIcon.svg'
import { ReactComponent as ChosenIcon } from 'images/chosenIcon.svg'
import { LanguageContext } from 'helpers'
import './style.scss'

export const PickerPlan = ({dataPlan, markers}) => {
    const { language } = useContext(LanguageContext)
    const [chosenMarkerId, setChosenMarkerId] = useState(null)
    const chosenMarker = useMemo(() => markers?.find(marker => marker.id === chosenMarkerId) ?? null, [chosenMarkerId, markers])
    const chosenMarkerCategories = useMemo(() => chosenMarker?.device_categories ?? [], [chosenMarker])
  
    const handleChosenMarker = (markerId) => {
        if (markerId === chosenMarkerId) {
            setChosenMarkerId(null)
        } else {
            setChosenMarkerId(markerId)
        }
    }

    return (
        <div className="plan_img">
            <img src={dataPlan?.path} className="plan" alt=''/>
            {markers.map((marker, index) => 
                <>
                    <div
                        key={index}
                        className="marker-picker"
                        style={{
                            top: marker.y,
                            left: marker.x,
                        }}
                    >
                        <div className="marker-picker__num">
                            <Typography className="marker-picker__num__title">
                                № {index+1}
                            </Typography>
                        </div>
                        <ChosenIcon
                            className="cursor"
                            onClick={() => handleChosenMarker(marker.id)}
                        />
                    </div>
                    {chosenMarker && (<div className="marker-wrapper-picker" style={{
                        top: chosenMarker.y + 70, left: chosenMarker.x - 142, zIndex: 2
                    }}>
                        <CloseIcon onClick={() => setChosenMarkerId(null)} className="marker-wrapper-picker_close cursor"/>
                        <List
                            className="list-element-picker"
                            dataSource={chosenMarkerCategories}
                            locale={{
                                emptyText: (
                                    <Empty description={language === 'ru'? `Нет данных для отображения`: `No data to display`} />
                                )
                            }}
                            renderItem={(item) => (
                                <div key={item.id}>
                                    <Typography className="marker-measurer_device-title">{item.device_category.title}</Typography>
                                    <div className="marker-measurer__coment">
                                        <TextArea
                                            className="marker-measurer__coment_title"
                                            value={item.comment}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            )}
                        />
                    </div>)}
                </>
            )}
        </div>
        
    )
}