import { Modal as ModalAntd } from 'antd'

export const Modal = ({ isModalOpen, handleOk, handleCancel, component }) => {
    return (
        <ModalAntd
            title=""
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={(<></>)}
        >
            {component}
        </ModalAntd>
    )
}