import { HTTP_METHODS } from 'helpers'
import { Fetcher } from 'service'

const fetcher = new Fetcher()

export const requestClientsMeasurer = (lang) =>
    fetcher.requestToReceive({
        url: 'measurer/clients',
        params: {lang},
        method: HTTP_METHODS.GET
    })

export const requestClientMeasurerApi = (id) =>
    fetcher.requestToReceive({
        url: `measurer/clients/${id}`,
        method: HTTP_METHODS.GET,
    })

export const requestAddClientMeasurer = (data) =>
    fetcher.requestToReceive({
        url: 'measurer/clients',
        method: HTTP_METHODS.POST,
        data
    })

export const requestEditClientMeasurerApi = (id, data, lang) =>
    fetcher.requestToReceive({
        url: `measurer/clients/${id}`,
        method: HTTP_METHODS.PUT,
        params: { lang },
        data
    })

export const requestTypeClientsMeasurerApi = (lang) =>
    fetcher.requestToReceive({
        url: 'measurer/clients/types/list',
        params: {lang},
        method: HTTP_METHODS.GET
    })

export const requestClientReadyMeasurerApi = (id) =>
    fetcher.requestToReceive({
        url: `measurer/clients/${id}/ready`,
        method: HTTP_METHODS.PATCH,
    })

export const requestClientNotReadyMeasurerApi = (id) =>
    fetcher.requestToReceive({
        url: `measurer/clients/${id}/not-ready`,
        method: HTTP_METHODS.PATCH,
    })